import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles, List, ListItem, ListItemIcon, ListItemText, Collapse } from '@material-ui/core';
import {
	ExpandLess as ExpandLessIcon,
	ExpandMore as ExpandMoreIcon,
	ViewQuilt as OverviewIcon,
	LocationCity as PropertiesIcon,
	Map as MapIcon,
	Notifications as AlarmIcon,
	List as ActionIcon,
	Ballot as DeviationIcon,
	// BarChart as AnalysisIcon,
	OfflineBoltRounded as AutomationIcon,
	Description as ReportIcon,
	// HeadsetMic as IssuesIcon,
	// Assignment as AssignmentsIcon,
	Build as AdministrationIcon,
	ShowChart as ShowChartIcon,
} from '@material-ui/icons';
import chroma from 'chroma-js';

import { PAGES, VERSION } from '../constants';
import * as colors from '../colors';
import Notify from './Notify';
import { getStateVariables, STORE } from '../redux/selectors';
import { setPage } from '../redux/actionCreators';
import { CustomDivider } from './CustomDivider';

const SELECTION_PARTS = Object.freeze({ background: 0, text: 1 });

/**
 * Draws a left-aligned sidebar used for navigating between pages on the website.
 * @param {String} cssPosition : CSS position attribute applied to root sidebar element
 * @param {Function} onRedirect : Called when a page-redirect link is clicked in the sidebar
 */
function Sidebar(props) {
	const [expandedLinkIds, setExpandedLinkIds] = useState([]);
	const history = useHistory();
	const location = useLocation();

	/* useEffect(() => {
		const isAutomationTabDisabled = props.userInfo.username !== 'meliox' && props.userInfo.username !== 'jonas.bengtsson@bastec.se';
		const isAdministrationTabDisabled = props.userInfo.propertyAccess !== 'all';

		if (isAutomationTabDisabled && location.pathname === '/automation') {
			history.push('/overview');
		}
		if (isAdministrationTabDisabled && location.pathname.includes('/administration')) {
			console.log(isAdministrationTabDisabled);
			history.push('/overview');
		}
	}, [props.userInfo.username, props.userInfo.propertyAccess, location.pathname, history]); */

	const betaUserOptions = new Array();
	if (props.userInfo.betaUserOptions !== undefined) {
		Object.keys(props.userInfo.betaUserOptions).forEach(function (key) {
			betaUserOptions.push(props.userInfo.betaUserOptions[key]);
		});
	}

	useEffect(() => {
		const isAutomationTabDisabled = !betaUserOptions.includes('automationBeta');
		const isAdministrationTabDisabled = !(props.userInfo.authorizationAccess === 'all' || props.userInfo.authorizationAccess === 'parents');
		if (location.pathname.includes('/actions')) {
			history.replace('/overview');
		}
		const delay = 1;
		const timeoutId = setTimeout(() => {
			if (isAdministrationTabDisabled && location.pathname.includes('/administration')) {
				history.replace('/overview');
			}
			if (isAutomationTabDisabled && location.pathname.includes('/automation')) {
				history.replace('/overview');
			}
			const isChartsTabDisabled = !betaUserOptions.includes('chartsBeta');
			if (isChartsTabDisabled && location.pathname.includes('/charts')) {
				history.replace('/overview');
			}
		}, delay);
		return () => clearTimeout(timeoutId);
	}, [props.userInfo.username, props.userInfo.authorizationAccess, location.pathname, history, props.userInfo.betaUserOptions]);

	const links = [
		{ ...PAGES.overview, icon: <OverviewIcon /> },
		{ ...PAGES.properties, icon: <PropertiesIcon /> },
		{ ...PAGES.map, icon: <MapIcon /> },
		{ ...PAGES.alarms, icon: <AlarmIcon /> },
		/*{ ...PAGES.actions, icon: <ActionIcon /> },*/
		{ ...PAGES.deviations, icon: <DeviationIcon /> },
		{ ...PAGES.reports, icon: <ReportIcon /> },
		{
			...(props.userInfo.authorizationAccess === 'all' || props.userInfo.authorizationAccess === 'parents'
				? { ...PAGES.administration, icon: <AdministrationIcon /> }
				: { id: 'administration', label: '', icon: null, disabled: true }),
		},
		{ 
			...(betaUserOptions.includes('chartsBeta')
				? { ...PAGES.charts, icon: <ShowChartIcon /> }
				: { id: 'charts', label: '', icon: null, disabled: true }),
		},

		// { ...PAGES.analysis, icon: <AnalysisIcon /> },
		/* {
			...PAGES.automation,
			icon: <AutomationIcon />,
			disabled: props.userInfo.username !== 'meliox' && props.userInfo.username !== 'jonas.bengtsson@bastec.se',
		}, */
		{
			//...(props.userInfo.username === 'meliox' || props.userInfo.username === 'jonas.bengtsson@bastec.se'
			...(betaUserOptions.includes('automationBeta')
				? { ...PAGES.automation, icon: <AutomationIcon /> }
				: { id: 'automation', label: '', icon: null, disabled: true }),
		},
		// { ...PAGES.issues, icon: <IssuesIcon /> },
		// { ...PAGES.assignments, icon: <AssignmentsIcon /> },
		/* {
			...PAGES.administration, icon: <AdministrationIcon />,
			disabled: props.userInfo.propertyAccess !== 'all'
		}, */
		/* {
			...PAGES.administration, icon: <AdministrationIcon />,
			disabled: props.userInfo.propertyAccess !== 'all'
		}, */
	];
	// remove all dissabled links
	


	function getSelectionStyle(selectionPart, linkId) {
		let style = {};

		if (selectionPart === SELECTION_PARTS.background)
			style = {
				background: chroma(colors.bgcolor).darken(0.35).hex(),
			};
		else if (selectionPart === SELECTION_PARTS.text) style = { fontWeight: '500' };

		return props.currentPage === linkId ? style : null;
	}

	function toggleLinkExpansion(linkId) {
		if (expandedLinkIds.includes(linkId)) setExpandedLinkIds(expandedLinkIds.filter(expandedLinkId => expandedLinkId !== linkId));
		else setExpandedLinkIds(expandedLinkIds.concat([linkId]));
	}

	function onLinkClick(link) {
		if (link.id === 'properties')
			props.callbacks['on-sidebar-property-click'] &&
				props.callbacks['on-sidebar-property-click'].forEach(callback => callback.callback());

		if (typeof props.onRedirect === 'function') props.onRedirect();
	}

	function getListLink(link) {
		const isExpanded = link.children && expandedLinkIds.includes(link.id);

		return (
			<div key={link.id}>
				<ListItem
					button
					component={link.children ? null : Link}
					to={'/' + link.id}
					onClick={() => (link.children ? toggleLinkExpansion(link.id) : onLinkClick(link))}
					style={getSelectionStyle(SELECTION_PARTS.background, link.id)}
					disabled={link.disabled}
				>
					<ListItemIcon style={{ color: '#eee', marginLeft: '0.6rem' }}>{link.icon}</ListItemIcon>
					<ListItemText
						primary={link.label}
						classes={makeStyles({ primary: getSelectionStyle(SELECTION_PARTS.text, link.id) })()}
					/>
					{link.children ? isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon /> : null}
				</ListItem>

				<Collapse in={isExpanded} timeout='auto'>
					<List style={{ marginLeft: '1.5rem' }}>{link.children && link.children.map(link => getListLink(link))}</List>
				</Collapse>
			</div>
		);
	}

	return (
		<div
			id='sidebar'
			style={{
				color: '#fff',
				background: colors.bgcolor,
				boxShadow: '0 0 0.35rem #000a',
				position: props.cssPosition || 'fixed',
				width: '16rem',
				top: '0',
				left: '0',
				zIndex: '1000',
				display: 'flex',
				flexDirection: 'column',
				height: '100vh',
				overflowY: 'auto',
				scrollbarWidth: 'none',
				msOverflowStyle: 'none'
			}}
		>
            <style>
                {`
                    #sidebar::-webkit-scrollbar {
                        display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
                    }
                `}
			</style>
			
			<div style={{ textAlign: 'center', marginTop: '2vh', padding: '0' }}>
				<Link to={'/' + PAGES.overview.id} onClick={props.onRedirect || null}>
					<img
						src={require('../assets/meliox-connect.png').default}
						style={{ width: '63%', filter: 'drop-shadow(0px 2px 2px #0008)' }}
						alt='Meliox Connect'
					/>
				</Link>
			</div>

			<div style={{ flex: '1 1 auto', marginTop: '2vh' }}>
				<List>{links.map(link => getListLink(link))}</List>
			</div>

			<div style={{ textAlign: 'center', padding: '1vh 0' }}>
				<Notify />

				{/*<CustomDivider color='#fff3' />*/}

				{/*<img
					alt='Meliox logo'
					src={require('../assets/meliox-logo-white.png').default}
					style={{ width: '52%', marginTop: '0.59rem', filter: 'drop-shadow(0px 2px 2px #0004)' }}
				/>*/}
				{/*<h5 style={{ fontSize: '120%', fontWeight: '300', marginTop: '1.5rem', display: 'flex', justifyContent: 'center' }}>
					Version <div style={{ fontWeight: '500', marginLeft: '0.3rem' }}>{VERSION}</div>
				</h5>*/}
			</div>
		</div>
	);
}

const SidebarHoc = connect(getStateVariables(STORE.currentPage, STORE.callbacks, STORE.userInfo), { setPage })(Sidebar);
export { SidebarHoc as Sidebar };
//export const getUserInfo = state => state.userInfo;
