import React, { useEffect, useState } from 'react';
import { GET_AI_CONTROL, QUERY_SENSORDATA_NEW_AND_VIEW } from '../../utility-functions/gqlQueries';
import { useQuery, useLazyQuery, gql } from '@apollo/client';
import { LinearProgress, Paper, Tooltip } from '@material-ui/core';
import * as colors from '../../colors';
import { makeStyles } from '@material-ui/styles';
import Guage from '../Guage';
import { MultiLineChart } from '../NivoCharts';
import { GRAPH_INTERVAL_TYPES, INTERVAL_VALUES } from '../../constants';
import OverviewGraph from './OverviewGraph';
import { createDeepCopy } from '../../utility-functions';
import { addInterval } from '../../utility-functions/intervalToDate';
import i18n from '../../i18n';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import chroma from 'chroma-js';
import { format } from 'date-fns';

const QUERY_GET_LATEST_TOTAL_CURVE_CHANGE = gql`
	query ($filter: LatestTotalCurveChangeFilter) {
		getLatestTotalCurveChange(filter: $filter) {
			timestamp
			locationid
			system
			ducid
			total_curvechange
		}
	}
`;

const useStyle = makeStyles(theme => ({
	card: {
		background: colors.primary,
		color: 'white',
		padding: '1rem',
	},
}));

function OverviewCard({ title, value, style, error, tooltip, ...props }) {
	const classes = useStyle(error);
	const errorFrame = {
		border: `2px solid ${colors.failure}`,
	};

	return (
		<Tooltip title={error || tooltip} placement='top'>
			<Paper className={classes.card} style={{ ...style, ...(error ? errorFrame : {}) }}>
				{title && <h3>{title}</h3>}
				<div style={{ display: 'flex', justifyContent: 'right', marginTop: '0.5rem' }}>
					<h1>{value}</h1>
				</div>
			</Paper>
		</Tooltip>
	);
}

const RESET_GRAPH_MARGIN = {
	right: 20,
	top: 10,
};

const useStyles = makeStyles(theme => ({
	graphWrapper: {
		border: '1px solid lightgrey',
		borderRadius: '1rem',
		padding: '0.5rem',
		paddingBottom: '2.5rem',
		flex: 1,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		marginBottom: '1.6rem',
	},
	graphGroup: {
		height: '17rem',
		display: 'flex',
		width: '100%',
	},
	graphWrapperContent: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		flex: 1,
		position: 'relative',
	},
}));

function GraphWrapper({ title, children, style, loading, ...props }) {
	const classes = useStyles();
	return (
		<div className={classes.graphWrapper} style={{ ...style }}>
			<div
				style={{
					marginLeft: 25,
					marginBottom: '0.2rem',
					fontSize: '1.3rem',
					fontWeight: 'bold',
					overflow: 'hidden',
				}}
			>
				{title}
			</div>
			<div className={classes.graphWrapperContent}>
				{children}
				{loading ? <LinearProgress style={{ width: '100%', position: 'absolute', bottom: 0 }} /> : undefined}
			</div>
		</div>
	);
}

const chartProps = {
	animate: true,
	isInteractive: true,
	useMesh: false,
	legends: undefined,
	margin: { right: 20, left: 50, bottom: 40 },
};

const DATAPOINT_FREQUENCY = '1 day';
const INTERVAL = 'week';

const CURVE_X_PATTERN = /^X\d+$/;
const CURVE_Y_PATTERN = /^Y\d+$/;

function mapSensorIdToSensorType(sensors) {
	const map = sensors.reduce((acc, sensor) => {
		if (sensor.sensorid === '0') return acc;
		let sensorType = sensor.sensortype;
		if (CURVE_X_PATTERN.test(sensorType)) return acc;
		if (CURVE_Y_PATTERN.test(sensorType)) return acc;
		return {
			...acc,
			[parseInt(sensor.sensorid)]: sensorType,
		};
	}, {});
	return map;
}

function getAverageIndoorGuage(data) {
	if (!data.length) return undefined;
	const latestTempReading = data.map(({ sensordata }) => sensordata[sensordata.length - 1]?.y).filter(temp => temp != null);
	return latestTempReading.reduce((acc, temp) => acc + temp, 0) / latestTempReading.length;
}

function getAverageIndoorHistory(data) {
	if (!data.length) return [];
	const averagePerDate = data[0].sensordata.map(({ x }, i) => {
		const dataPoint = {
			x,
			y:
				data.reduce((acc, { multiplier, sensordata }) => {
					const { y } = sensordata[i];
					return acc + (multiplier && y ? multiplier * y : y);
				}, 0) / data.length,
		};
		return dataPoint;
	});
	return [
		{
			sensorid: 'indoortemp',
			sensordata: averagePerDate,
		},
	];
}

const CURVE_MIN_THRESHOLD = -6;
const CURVE_MAX_THRESHOLD = 2;

function getCurveChangeSummary(dataX, dataY, totalCurveChangeHistory) {
	if (!dataY.length) {
		return [];
	}
	const replaceX = (!dataX.length || dataX.every(({ value }) => value === null));
	if (replaceX) {
		dataX = dataY.map(({ value }, i) => ({
			value: i,
			sensortype: `X${i}`,
		}));
	}

	//order them by sensortype
	dataX.sort((a, b) => a.sensortype.localeCompare(b.sensortype));
	dataY.sort((a, b) => a.sensortype.localeCompare(b.sensortype));

	function calcDucMultiplierConstant(value) {
		let multiplier = 1;
		while (value > 100) {
			value /= 10;
			multiplier *= 10;
		}
		return multiplier;
	}

	const original = dataX.map(({ value }, i) => {
		const yValue = dataY[i].value;
		const multiplier = calcDucMultiplierConstant(yValue);
		return {
			x: replaceX ? i : value / multiplier,
			y: yValue / multiplier,
		};
	});
	const min = original.map(({ x, y }, i) => ({
		x: x,
		y: y !== null ? y + CURVE_MIN_THRESHOLD : y,
	}));
	const max = original.map(({ x, y }, i) => ({
		x: x,
		y: y !== null ? y + CURVE_MAX_THRESHOLD : y,
	}));

	const latestCurveChange = totalCurveChangeHistory[0]?.y || 0;

	return [
		{ id: i18n.t('aiOverview.optimized'), data: original.map(({ x, y }) => ({ x, y: y + latestCurveChange })) },
		{ id: i18n.t('aiOverview.original'), data: original },
		{ id: i18n.t('aiOverview.max'), data: max },
		{ id: i18n.t('aiOverview.min'), data: min },
	];
}

const ENERGY_EXPENDED_PER_C = 5;

function calculateEnergyExpended(totalCurveChangeHistory) {
	const averageCurveChange = totalCurveChangeHistory.reduce((acc, { y }) => acc + y, 0) / totalCurveChangeHistory.length;
	return Math.round(averageCurveChange * ENERGY_EXPENDED_PER_C * 100) / 100;
}

function calculateAverageCurveHangeHistory(totalCurveChangeHistory) {
	const averageCurveChange = totalCurveChangeHistory.reduce((acc, { y }) => acc + y, 0) / totalCurveChangeHistory.length;
	return {
		id: 'Average',
		data: totalCurveChangeHistory.map(({ x }) => ({
			x,
			y: averageCurveChange,
		})),
	};
}

function calculateTotalExpended(curveChangeExpended, districtHeating) {
	if (!districtHeating.length) return 0;
	const dHData = districtHeating[0]?.sensordata || [];
	return (1 + curveChangeExpended / 100) * dHData.reduce((acc, { y }) => acc + y, 0);
}
// Min max og -6 +2
// optimised = total change + og
// getAiLogView -> total change

// curve change -> aiLog from db total_change

const DEFAULT_DATA = {
	X: [],
	Y: [],
	outdoortemp: [],
	indoortemp: [],
	supplytemp: [],
	electricity: [],
	districtHeating: [],
	mintemp: 0,
	maxtemp: 0,
};

const GRAPH_LINE_COLORS = {
	main: '#007FB2',
	average: '#FF5858',
	min: '#FF5858',
	original: '#007FB2',
	optimized: '#4CAF50',
	max: '#FF5858',
};

function AiOverview({ property, aiControls, ...props }) {
	const { t } = useTranslation();
	const [sensorToSensortype, setSensorToSensortype] = useState({});
	const [rawData, setRawData] = useState(createDeepCopy(DEFAULT_DATA));
	const [totalCurveChangeHistory, setTotalCurveChangeHistory] = useState({ id: t('generic.curveChange'), data: [] });
	const [doneLoading, setDoneLoading] = useState(false);

	const getInterval = () => {
		const endDate = addInterval(new Date(), 'hour', true);
		// Sets start of interval to 1 week ago
		const startDate = addInterval(endDate, INTERVAL, false, -1);
		return [startDate, endDate];
	};

	const [getSensorData, { loading: dataLoading, error: dataError }] = useLazyQuery(QUERY_SENSORDATA_NEW_AND_VIEW, {
		onCompleted: ({ getSensorDataNew: data, getSensorView: view }) => {
			// When using a single call for all sensorids we do not know what id correspond to what type
			// split up the data into different types
			const max = aiControls.find(acd => acd.sensortype === 'maxtemp') || {};
			const min = aiControls.find(acd => acd.sensortype === 'mintemp') || {};
			const dataX = aiControls.filter(acd => CURVE_X_PATTERN.test(acd.sensortype)) || [];
			const dataY = aiControls.filter(acd => CURVE_Y_PATTERN.test(acd.sensortype)) || [];

			const filteredGraphData = {
				...createDeepCopy(DEFAULT_DATA),
				maxtemp: max?.maximum || max?.value || 0,
				mintemp: min?.minimum || min?.value || 0,
				X: dataX,
				Y: dataY,
			};

			data.forEach(sensor => {
				const sensorType = sensorToSensortype[sensor.sensorid];
				const sensorInfo = view.find(v => parseInt(v.sensorid) === sensor.sensorid) || {};
				if (!filteredGraphData[sensorType]) filteredGraphData[sensorType] = [];
				if (sensorInfo) {
					filteredGraphData[sensorType].push({
						...sensor,
						name: sensorInfo.name,
						unit: sensorInfo.unit,
						multiplier: sensorInfo.multiplier,
					});
				}
			});
			setRawData(filteredGraphData);
			setDoneLoading(true);
		},
	});

	const [getTotalCurveChange, { loading: totalCurveChangeLoading }] = useLazyQuery(QUERY_GET_LATEST_TOTAL_CURVE_CHANGE, {
		onCompleted: ({ getLatestTotalCurveChange: data }) => {
			const [startDate, _] = getInterval();
			const filteredData = data.filter(({ timestamp }) => new Date(timestamp) >= startDate);
			setTotalCurveChangeHistory({
				...totalCurveChangeHistory,
				data: filteredData.map(({ timestamp, total_curvechange }) => ({ x: timestamp, y: total_curvechange })),
			});
		},
	});

	useEffect(() => {
		const data = aiControls || [];
		function removeCurves(data) {
			return data.filter(sensor => !CURVE_X_PATTERN.test(sensor.sensortype) && !CURVE_Y_PATTERN.test(sensor.sensortype));
		}
		function removeMinMaxTemps(data) {
			return data.filter(({ sensortype }) => sensortype !== 'maxtemp' && sensortype !== 'mintemp');
		}
		const uniques = [...new Set(data.map(d => `${d.locationid}-${d.system}-${d.ducid}`))];
		const uniqueSets = uniques.map(u => {
			const [locationid, system, ducid] = u.split('-');
			return {
				locationid,
				system,
				ducid,
			};
		});

		setSensorToSensortype(mapSensorIdToSensorType(data));
		const sensorIds = [...new Set(removeMinMaxTemps(removeCurves(data)).map(sensor => parseInt(sensor.sensorid)))];
		const [startDate, endDate] = getInterval();
		getSensorData({
			variables: {
				sensorDataFilter: {
					sensorids: sensorIds,
					timestamp_interval: DATAPOINT_FREQUENCY,
					timestamp_lte: endDate,
					timestamp_gte: startDate,
					interval_type: 'mean',
				},
				sensorViewFilter: {
					sensorids: sensorIds,
				},
			},
		});

		const locationids = uniqueSets.map(u => Number(u.locationid));
		const systems = uniqueSets.map(u => u.system);
		const ducids = uniqueSets.map(u => Number(u.ducid));

		getTotalCurveChange({
			variables: {
				filter: {
					locationids: locationids,
					systems: systems,
					ducids: ducids,
				},
			},
		});
	}, []);

	const thereWasAnError = (existsSensor, message) => {
		const dataFetchError = t('aiOverview.dataFetchError');
		const noSensorError = t('aiOverview.noSensorOfThisType');
		if (dataError) return dataFetchError;
		if (!doneLoading) return undefined;
		if (existsSensor) return message || noSensorError;
		return undefined;
	};
	const filteredIndoorTemp = rawData.indoortemp.map(sensor => {
		const filteredData = sensor.sensordata.filter(({ y }) => y != 0 && y != null);
		return {
			...sensor,
			sensordata: filteredData,
		};
	});
	const averageIndoorHistory = getAverageIndoorHistory(filteredIndoorTemp);
	const averageIndoorGuage = getAverageIndoorGuage(averageIndoorHistory);

	const curveChangeSummary = getCurveChangeSummary(rawData.X, rawData.Y, totalCurveChangeHistory.data) || [];
	const curveChangeExpended = calculateEnergyExpended(totalCurveChangeHistory.data);

	const totalExpended = calculateTotalExpended(curveChangeExpended, rawData.districtHeating);

	const averageCurveChangeHistory = calculateAverageCurveHangeHistory(totalCurveChangeHistory.data);

	const energySavedLabel = totalCurveChangeHistory.data.length
		? `${curveChangeExpended >= 0 ? '+' : '-'}${Math.abs(curveChangeExpended)}%`
		: '-- %';
	const energyConsumedLabel = rawData.districtHeating.length ? `${totalExpended.toFixed(1)} kWh` : '-- kWh';

	const classes = useStyles();
	return (
		<div style={{ padding: '0.5rem' }}>
			<div style={{ display: 'flex', fontSize: '1.3rem', marginLeft: 25 }}>{`${t('aiOverview.reportFor')}: ${t(
				`timeSelect.${INTERVAL}`
			)}`}</div>
			<div style={{ display: 'flex', width: '100%', margin: '2rem 0 0 0', justifyContent: 'center' }}>
				<div style={{ height: '25rem', width: '55%' }}>
					<MultiLineChart
						data={curveChangeSummary}
						maxLegends={4}
						margin={{ bottom: 50 }}
						chartProps={{
							lineWidth: 3,
							enableSlices: 'x',
							xScale: { type: 'linear', min: 'auto', max: 'auto' },
							colors: [
								chroma(GRAPH_LINE_COLORS.optimized).brighten(0.3).hex(),
								chroma(GRAPH_LINE_COLORS.original).brighten(0.3).hex(),
								chroma(GRAPH_LINE_COLORS.min).brighten(0.3).hex(),
								chroma(GRAPH_LINE_COLORS.min).brighten(0.3).hex(),
							],
							sliceTooltip: ({ slice }) => (
								<CustomTooltip
									slices={slice.points}
									yFormat={({ y }) => y.toFixed(1) + ' °C'}
									xFormat={({ x }) => x.toFixed(1) + ' °C'}
								/>
							),
						}}
					/>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						flexGrow: 1,
						justifyContent: 'flex-start',
					}}
				>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<OverviewCard
							title={t('aiOverview.energySaved') + '*'}
							value={energySavedLabel}
							style={{ width: '50%' }}
							error={thereWasAnError(
								!rawData.X.length && !totalCurveChangeHistory.data.length && !totalCurveChangeLoading,
								t('aiOverview.requiresCurveChange')
							)}
							tooltip={t('aiOverview.energySavedInfo')}
						/>
						<OverviewCard
							title={t('aiOverview.energyConsumed')}
							value={energyConsumedLabel}
							style={{ marginLeft: '1rem', width: '50%' }}
							error={thereWasAnError(!rawData.districtHeating.length, t('aiOverview.requiresDistrictHeating'))}
							tooltip={t('aiOverview.energyConsumedInterval')}
						/>
					</div>
					{averageIndoorGuage !== undefined ? (
						<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '1rem' }}>
							<h2>{t('aiOverview.guageTitle')}</h2>
							<Guage
								value={Math.round(averageIndoorGuage * 10 || 0) / 10}
								minValue={0}
								unit={'°C'}
								ticks={[rawData.mintemp, rawData.maxtemp]}
								thresholds={[rawData.mintemp, rawData.maxtemp]}
							/>
						</div>
					) : undefined}
				</div>
			</div>
			{/* <GraphWrapper title={'Primärenergital'} style={{ height: '17rem', width: '100%' }}>
				<OverviewGraph data={[]} timeOptions={INTERVAL_VALUES.week} maxLegends={0} margin={RESET_GRAPH_MARGIN} />
			</GraphWrapper> */}
			<div className={classes.graphGroup}>
				<GraphWrapper loading={dataLoading} title={t('generic.curveChange')}>
					<DataError
						error={thereWasAnError(!rawData.X.length && !totalCurveChangeHistory.data.length && !totalCurveChangeLoading)}
					>
						<MultiLineChart
							data={[totalCurveChangeHistory, averageCurveChangeHistory]}
							margin={{ bottom: 50, top: 10, right: 120 }}
							multiToolip
							chartProps={{
								curve: 'linear',
								lineWidth: 4,
								xFormat: 'time:%Y-%m-%dT%H:%M:%S.%LZ',
								xScale: { type: 'time', format: '%Y-%m-%dT%H:%M:%S.%LZ' },
								colors: [
									chroma(GRAPH_LINE_COLORS.main).brighten(0.3).hex(),
									chroma(GRAPH_LINE_COLORS.average).brighten(0.3).hex(),
								],
								axisBottom: {
									orient: 'bottom',
									tickSize: 0,
									tickPadding: 12,
									tickRotation: 30,
									tickValues: INTERVAL_VALUES.week.chartTickCount,
									legend: '',
									format: val => format(new Date(val), INTERVAL_VALUES.week.dateFormat),
								},
								enableSlices: 'x',
								sliceTooltip: ({ slice }) => (
									<CustomTooltip
										slices={slice.points}
										yFormat={({ y }) => y.toFixed(1) + ' °C'}
										xFormat={({ x }) => format(x, 'd MMM HH:mm')}
									/>
								),
							}}
						/>
					</DataError>
				</GraphWrapper>
				<GraphWrapper
					loading={dataLoading}
					title={rawData.supplytemp[0]?.name || t('generic.supplyTemp')}
					style={{ marginLeft: '1rem' }}
				>
					<DataError error={thereWasAnError(!rawData.supplytemp.length)}>
						<OverviewGraph
							unit={'°C'}
							data={rawData.supplytemp}
							timeOptions={INTERVAL_VALUES.week}
							maxLegends={0}
							margin={RESET_GRAPH_MARGIN}
							dynamicTooltip
						/>
					</DataError>
				</GraphWrapper>
			</div>
			<div className={classes.graphGroup}>
				<GraphWrapper loading={dataLoading} title={t('generic.indoorTemp')}>
					<DataError error={thereWasAnError(!rawData.indoortemp.length)}>
						<OverviewGraph
							unit={'°C'}
							data={averageIndoorHistory}
							timeOptions={INTERVAL_VALUES.week}
							maxLegends={0}
							margin={RESET_GRAPH_MARGIN}
							dynamicTooltip
						/>
					</DataError>
				</GraphWrapper>
				<GraphWrapper loading={dataLoading} title={t('generic.outdoorTemp')} style={{ marginLeft: '1rem' }}>
					<DataError error={thereWasAnError(!rawData.outdoortemp.length)}>
						<OverviewGraph
							unit={'°C'}
							data={rawData.outdoortemp}
							timeOptions={INTERVAL_VALUES.week}
							maxLegends={0}
							margin={RESET_GRAPH_MARGIN}
							dynamicTooltip
						/>
					</DataError>
				</GraphWrapper>
			</div>
			<div className={classes.graphGroup}>
				<GraphWrapper loading={dataLoading} title={rawData.electricity[0]?.name || t('generic.electricity')}>
					<DataError error={thereWasAnError(!rawData.electricity.length)}>
						<OverviewGraph
							data={rawData.electricity || []}
							unit={'kWh'}
							timeOptions={INTERVAL_VALUES.week}
							maxLegends={0}
							margin={RESET_GRAPH_MARGIN}
							chartProps={chartProps}
							dynamicTooltip
						/>
					</DataError>
				</GraphWrapper>
				<GraphWrapper loading={dataLoading} title={t('constants.districtHeating')} style={{ marginLeft: '1rem' }}>
					<DataError error={thereWasAnError(!rawData.districtHeating.length)}>
						<OverviewGraph
							data={rawData.districtHeating}
							unit={'kWh'}
							timeOptions={INTERVAL_VALUES.week}
							maxLegends={0}
							margin={RESET_GRAPH_MARGIN}
							chartProps={chartProps}
							dynamicTooltip
						/>
					</DataError>
				</GraphWrapper>
			</div>
			<div>{t('aiOverview.energySavedInfo')}</div>
		</div>
	);
}

function CustomTooltip({ slices, yFormat, xFormat }) {
	return (
		<div
			style={{
				background: 'white',
				padding: '0.5rem',
				border: '1px solid lightgrey',
			}}
		>
			{slices.map((slice, i) => (
				<div key={i}>
					{slice.serieId}: {yFormat ? yFormat(slice.data) : slice.data.yFormatted}
					<div
						style={{
							width: '100%',
							height: '0.3rem',
							background: slice.serieColor,
							margin: '0.2rem 0 0.25rem 0',
						}}
					/>
				</div>
			))}
			<div style={{ marginTop: '0.5rem' }}>{xFormat ? xFormat(slices[0].data) : slices[0].data.xFormatted}</div>
		</div>
	);
}

function DataError({ error, children }) {
	if (error) return <Alert severity='error'>{error}</Alert>;
	return children;
}

export default AiOverview;
