import React, { useState, useEffect } from 'react';
import { Button, Checkbox } from '@material-ui/core';
import { CheckCircle as ConfirmIcon, RemoveCircle as AlarmIcon } from '@material-ui/icons';
import { connect } from 'react-redux';
import { gql, useQuery, useMutation } from '@apollo/client';
import { withRouter } from 'react-router';
import { format as formatDate } from 'date-fns';
import { useTranslation } from 'react-i18next';

import './AlarmList.scss';
import { CENTER_CONTENT_STYLE, PAGES, PROPERTY_TABS, QUERY_STRINGS } from '../constants';
import * as colors from '../colors';
import { drawTruncatedStr } from '../utility-functions';
import { STORE, getStateVariables } from '../redux/selectors';
import SelectionTable from './SelectionTable';
import EditPopup from './EditPopup';
import DialogWrapper from './DialogWrapper';
import EditableText from './EditableText';
import TooltipWrapper from './TooltipWrapper';

const GET_MULTI_DATA = gql`
	query ($alarmFilter: AlarmFilter, $confirmFilter: ConfirmFilter) {
		getAlarmView(filter: $alarmFilter) {
			sensorid
			locationid
			city
			street
			name
			unit
			earliestvalue
			maxvalue
			latestvalue
			earliesttimestamp
			latesttimestamp
			confirmtimestamp
			sensorref
		}
		getConfirms(filter: $confirmFilter) {
			confirmid
			sensorid
			userid
			comment
			timestamp
		}
	}
`;
const GET_KEYCLOAKUSERS = gql`
	query ($filter: KeycloakUserFilter!) {
		getKeycloakUsers(filter: $filter) {
			id
			firstName
			lastName
		}
	}
`;
const SET_CONFIRMS = gql`
	mutation ($confirmids: [ID!]!, $comment: String) {
		setConfirms(confirmids: $confirmids, comment: $comment) {
			confirmid
		}
	}
`;
const ADD_CONFIRMS = gql`
	mutation ($timestamp: Date!, $comment: String, $sensorids: [Int!]!) {
		addConfirms(timestamp: $timestamp, comment: $comment, sensorids: $sensorids) {
			confirmid
			timestamp
			comment
			sensorid
		}
	}
`;

const ECPALARM_PREFIX = 'ECPALARM-';
//const ECP_ALARM_CONFIRM_URL = 'http://localhost:4000/confirm';
const ECP_ALARM_CONFIRM_URL = process.env.REACT_APP_ECP_ALARM_URL + '/confirm';  /*'http://localhost:4000/confirm';*/
const AlarmType = {
	Alarm: 1,
	Confirm: 2,
	EcpAlarm: 3,
	EcpConfirm: 4,
};

function typeSort(a, b) {
	const effectiveDates = {
		a:
			new Date(a.latesttimestamp || a.confirmtimestamp).getTime() *
			(a.type === AlarmType.Alarm || a.type === AlarmType.EcpAlarm ? 2 : 1),
		b:
			new Date(b.latesttimestamp || b.confirmtimestamp).getTime() *
			(b.type === AlarmType.Alarm || b.type === AlarmType.EcpAlarm ? 2 : 1),
	};
	return effectiveDates.a > effectiveDates.b ? -1 : effectiveDates.a < effectiveDates.b ? 1 : a.name < b.name ? -1 : 1;
}

/**
 * Draws a table with alarm-info where alarms can be confirmed. Looks differently depending on if it's global or property-specific
 * @param {boolean} isPropertySpecific : Whether the list should be specific to the currently selected property
 */
function AlarmList(props) {
	const [currentPropertyId, setCurrentPropertyId] = useState(props.currentProperty);
	const [hasLoaded, setHasLoaded] = useState(false);
	const [selections, setSelections] = useState([]);
	const [showConfirmPopup, setShowConfirmPopup] = useState(false);
	const [selectedConfirmId, setSelectedConfirmId] = useState();
	const [showCommentPopup, setShowCommentPopup] = useState(false);
	const [showConfirmed, setShowConfirmed] = useState(false);

	const { t } = useTranslation();
	const isGlobal = !props.isPropertySpecific;
	const maxColumnLength = isGlobal ? 14 : 29;
	const isOnlyEcpSels = selections.length && selections.every(sel => sel.sensorid.startsWith(ECPALARM_PREFIX));

	const shouldAllowRowClick = props.userInfo.authorizationAccess === 'all' || props.userInfo.authorizationOptions?.length === 2 || props.userInfo.authorizationAccess === 'parents';

	const _locationids = props.properties.filter(pro => isGlobal || pro.locationid === props.currentProperty).map(pro => Number(pro.locationid));
	const _sensorids = props.sensors.filter(sen => isGlobal || sen.locationid === props.currentProperty).map(sen => Number(sen.sensorid));
	const multiDataQuery = useQuery(GET_MULTI_DATA, {
		skip: !props.sensors.length,
		variables: {
			alarmFilter: { locationids: _locationids },
			confirmFilter: { sensorids: _sensorids },
		},
		onCompleted: () => setHasLoaded(true),
	});

	const alarms = (multiDataQuery.data?.getAlarmView || []).map(alarm => {
		const sensor = props.sensors.find(sen => Number(sen.sensorid) === Number(alarm.sensorid)) || {};
		const multiplier = sensor.multiplier || 1;
		return {
			...alarm,
			earliestvalue: alarm.earliestvalue * multiplier,
			maxvalue: alarm.maxvalue * multiplier,
			latestvalue: alarm.latestvalue * multiplier,
			lowerthreshold: sensor.lowerthreshold,
			upperthreshold: sensor.upperthreshold,
			type: AlarmType.Alarm,
		};
	});
	const confirms = (multiDataQuery.data?.getConfirms || [])
		.filter(
			con => isGlobal || props.sensors.find(sen => Number(sen.sensorid) === Number(con.sensorid))?.locationid === currentPropertyId
		)
		.map(con => {
			const sensor = props.sensors.find(sen => Number(sen.sensorid) === Number(con.sensorid));
			return {
				...sensor,
				...((isGlobal && props.properties.find(pro => Number(pro.locationid) === Number(sensor?.locationid))) || {}),
				...con,
				confirmtimestamp: con.timestamp,
				type: AlarmType.Confirm,
			};
		});
	const tableData = [...alarms, ...(showConfirmed ? [...confirms] : [])];
	const selectedConfirm = confirms.find(con => con.confirmid === selectedConfirmId);

	const keycloakUserQuery = useQuery(GET_KEYCLOAKUSERS, {
		skip: !tableData.length,
		variables: { filter: { ids: [...new Set(tableData.filter(dat => dat.userid).map(dat => dat.userid))] } },
	});
	const [setConfirms] = useMutation(SET_CONFIRMS, { onCompleted: () => multiDataQuery.refetch() });
	const [addConfirms] = useMutation(ADD_CONFIRMS, { onCompleted: () => multiDataQuery.refetch() });

	useEffect(() => {
		if (!isGlobal && props.currentProperty !== currentPropertyId) {
			setCurrentPropertyId(props.currentProperty);
			multiDataQuery.refetch();
		}
		// eslint-disable-next-line
	}, [props.currentProperty]);

	useEffect(() => {
		if (props.hasFinishedLoading && !props.sensors.length) setHasLoaded(true);
		// eslint-disable-next-line
	}, [props.hasFinishedLoading]);

	function formatTimestamp(timestamp, showLongFormat = false) {
		return timestamp ? formatDate(new Date(timestamp), showLongFormat ? 'yyyy-MM-dd HH:mm' : 'MMM dd HH:mm') : '';
	}

	return (
		<>
			<div style={{ position: 'relative', margin: isGlobal ? '' : '1rem', color: colors.text }}>
				<SelectionTable
					localization={{
						title: isGlobal ? t('alarmList.tableTitle') : t('generic.alarm_other'),
						nRowsSelected: () => (
							<Button
								onClick={() => setShowConfirmPopup(!showConfirmPopup)}
								variant='outlined'
								size='small'
							>
								<ConfirmIcon style={{ width: '1rem', height: '1rem', margin: '0 5px 0 -2px' }} />
								{t('alarmList.ackSelectedCount', { count: selections.length })}
							</Button>
						),
					}}
					data={tableData.sort(typeSort)}
					dataId='sensorid'
					selectedIds={selections.map(sel => sel.sensorid)}
					disabledIds={tableData
						.filter(dat => dat.type === AlarmType.Confirm /* || dat.type === AlarmType.EcpConfirm */)
						.map(dat => dat.sensorid)}
					onSelectionChange={sel => setSelections(sel)}
					columns={[
						{
							render: row =>
								row.type === AlarmType.Alarm ? (
									<AlarmIcon style={{ color: colors.failure, padding: '0.1rem 0 0' }} />
								) : row.type === AlarmType.Confirm ? (
									<ConfirmIcon style={{ color: colors.success, padding: '0.1rem 0 0' }} />
								) : row.type === AlarmType.EcpAlarm ? (
									<div style={{ color: colors.failure, fontWeight: '900' }}>{t('generic.bms')}</div>
								) : (
									<div style={{ color: colors.success, fontWeight: '900' }}>{t('generic.bms')}</div>
								),
							customSort: typeSort,
						},
						...(isGlobal ? [{ title: t('generic.city'), field: 'city' }] : []),
						...(isGlobal ? [{ title: t('generic.address'), field: 'street' }] : []),
						{ title: t('generic.name'), field: 'name' },
						{
							title: t('alarmList.alarmValue'),
							render: row =>
								row.type === AlarmType.Alarm ? (
									<TooltipWrapper
										text={
											<div style={{ padding: '0.18rem 0.12rem 0.06rem', fontSize: '105%' }}>
												{`${t('alarmList.earliestAlarmValue')} ${Number(row.earliestvalue.toFixed(1))} ${row.unit || ''
													}`}
												<br />
												{`${t('alarmList.highestAlarmValue')} ${Number(row.maxvalue.toFixed(1))} ${row.unit || ''}`}
												<br />
												{`${t('alarmList.latestAlarmValue')} ${Number(row.latestvalue.toFixed(1))} ${row.unit || ''
													}`}
												<br />
											</div>
										}
									>
										<div>{`${Number(row.latestvalue.toFixed(1))} ${row.unit || ''}`}</div>
									</TooltipWrapper>
								) : (
									<div />
								),
						},
						{
							title: t('alarmList.alarmTime'),
							field: 'latesttimestamp',
							render: row => (
								<TooltipWrapper text={<div>{t('alarmList.earliestAlarm') + formatTimestamp(row.earliesttimestamp)}</div>}>
									<div>{formatTimestamp(row.latesttimestamp)}</div>
								</TooltipWrapper>
							),
						},
						{
							title: t('generic.acknowledged_one'),
							render: row => formatTimestamp(row.confirmtimestamp),
							customSort: (a, b) => ((a.confirmtimestamp || '2000') > (b.confirmtimestamp || '2000') ? -1 : 1),
						},
						showConfirmed
							? {
								title: t('generic.comment'),
								disableClick: true,
								//sorting: false,
								render: row => (
									<div
										onClick={() => {
											setSelectedConfirmId(row.confirmid);
											setShowCommentPopup(true);
										}}
										style={{ cursor: 'pointer' }}
									>
										{drawTruncatedStr(row.type === AlarmType.Confirm ? row.comment || '-' : '', maxColumnLength)}
									</div>
								),
							}
							: {},
					]}
					/* onRowClick={row => {
						if (shouldAllowRowClick) {
							props.history.push(
								`/${PAGES.properties.id}/${row.locationid}/` +
								(row.type === AlarmType.Alarm || row.type === AlarmType.Confirm
									? `${PROPERTY_TABS.meters.id}?${QUERY_STRINGS.sensorId.id}=${row.sensorid}&${QUERY_STRINGS.alarm.date}=${row.earliesttimestamp}&${QUERY_STRINGS.alarm.value}=${row.earliestvalue}`
									: `${PROPERTY_TABS.externalControlPanels.id}`)
							)
						}
					}
					} */
					onRowClick={shouldAllowRowClick
						? (row) => {
							props.history.push(
								`/${PAGES.properties.id}/${row.locationid}/` +
								(row.type === AlarmType.Alarm || row.type === AlarmType.Confirm
									? `${PROPERTY_TABS.meters.id}?${QUERY_STRINGS.sensorId.id}=${row.sensorid}&${QUERY_STRINGS.alarm.date}=${row.earliesttimestamp}&${QUERY_STRINGS.alarm.value}=${row.earliestvalue}`
									: `${PROPERTY_TABS.externalControlPanels.id}`)
							);
						}
						: //null
						(row) => {
							props.history.push(
								`/${PAGES.properties.id}/${row.locationid}/` +
								(row.type === AlarmType.Alarm || row.type === AlarmType.Confirm
									? `${PROPERTY_TABS.meters.id}?${QUERY_STRINGS.sensorId.id}=${row.sensorid}&${QUERY_STRINGS.alarm.date}=${row.earliesttimestamp}&${QUERY_STRINGS.alarm.value}=${row.earliestvalue}`
									: `${PROPERTY_TABS.alarmHistory.id}`)
							);
						}
					}
					style={isGlobal ? { width: CENTER_CONTENT_STYLE.width } : {}}
					tableProps={{
						maxColumnLength,
						isLoading: !hasLoaded,
						options: {
							pageSize: isGlobal ? 14 : 12,
							pageSizeOptions:
								tableData.length > (isGlobal ? 14 : 12)
									? [isGlobal ? 14 : 12, Math.floor(tableData.length / 2), tableData.length]
									: [],
						},
					}}
				/>

				<div style={{ position: 'absolute', top: '0.8rem', right: '20.5rem', fontSize: '15px', color: '#000c' }}>
					<Checkbox checked={showConfirmed} color='primary' onChange={e => setShowConfirmed(e.target.checked)} />
					{t('alarmList.showAcked')}
				</div>
			</div>

			<EditPopup
				text={{
					title: isOnlyEcpSels ? t('alarmList.confirmAck') : t('alarmList.addComment'),
					save: isOnlyEcpSels ? t('generic.confirm') : t('generic.acknowledge'),
				}}
				fields={isOnlyEcpSels ? [] : [{ id: 'confirmcomment', label: t('alarmList.ackComment'), multiline: true }]}
				isOpen={showConfirmPopup}
				canSave={true}
				onClose={() => setShowConfirmPopup(!showConfirmPopup)}
				onSave={opts => {
					setShowConfirmPopup(!showConfirmPopup);
					setSelections([]);
					const modbusinfo = [];
				//	let sensorref = '';
				let ecpId = '';
				let address = '';
				let changedAddress = '';
				let value = 0;
					selections.forEach(selection => {
						// Define the regular expression pattern to extract externalcontrolpanelid
						//const regex = /^(ducid|.*\| ([0-9]+) \| 9[0-9]*)$/;
						const regex = /ducid \| (\d+) \| (9\d+)/;
						const match = regex.exec(selection.sensorref);
						//sensorref = selection.sensorref;
						if (match) {
							// Extracted externalcontrolpanelid from sensorref
							ecpId = match[1];
							address = match[2];
							if (address.endsWith('1')) {
								changedAddress = (parseInt(address, 10) + 2).toString();
								value = 1;
							} else {
								value = 0;
							}
							const matchingPanel = props.externalControlPanels.find(panel => panel.externalcontrolpanelid === ecpId);
							if (matchingPanel) {
								const data = {
									serverip: matchingPanel.serverip === 'danfoss' ? '95.217.164.71' : matchingPanel.serverip, ip: matchingPanel.ip,
									ducid: matchingPanel.ducid,
									port: matchingPanel.port,
									address: changedAddress,
									value: value,

									/* serverip: matchingPanel.serverip || 'danfoss',
									ip: matchingPanel.ip || '12',
									ducid:matchingPanel.ducid	|| '1',
									port: matchingPanel.port	|| '502',
									address:address	|| '1',
									value: 0, */
								};
								modbusinfo.push(data);
							}
						}
					}
					);
					const payloadString = `comment:${opts.confirmcomment || ''}|modbusinfo:${JSON.stringify(modbusinfo)}|ecpId:${ecpId}|address:${address}`;
					//const payloadString = `comment:${opts.confirmcomment || ''}|modbusinfo:${JSON.stringify(modbusinfo)}`;
					addConfirms({
						variables: {
							timestamp: new Date().toISOString(),
							comment: payloadString,
							sensorids: selections
								.filter(sel => !sel.sensorid.startsWith(ECPALARM_PREFIX))
								.map(sel => Number(sel.sensorid)),
						},
					});
					/* selections.forEach(selection => {
						axios
							.post(ECP_ALARM_CONFIRM_URL, { sensorid: selection.sensorid })
							.then(r => setTimeout(() => multiDataQuery.refetch(), 12000));
					}); */
				}}
			/>

			<DialogWrapper
				title={t('alarmList.ackComment')}
				dialogProps={{ open: showCommentPopup, onClose: () => setShowCommentPopup(false) }}
				width='22rem'
			>
				<EditableText
					text={selectedConfirm?.comment}
					onSave={newText => setConfirms({ variables: { confirmids: [selectedConfirm?.confirmid], comment: newText } })}
				/>
				<div style={{ textAlign: 'right', marginTop: '1rem', fontWeight: '500' }}>
					<div>
						{(() => {
							const user = keycloakUserQuery.data?.getKeycloakUsers?.find(use => use.id === selectedConfirm?.userid);
							return user ? (user.firstName || '') + ' ' + (user.lastName || '') : '';
						})()}
					</div>
					<div style={{ fontSize: '83%', color: '#000a' }}>{formatTimestamp(selectedConfirm?.confirmtimestamp, true)}</div>
				</div>
			</DialogWrapper>
		</>
	);
}

export default connect(
	getStateVariables(
		STORE.hasFinishedLoading,
		STORE.currentProperty,
		STORE.sensors,
		STORE.properties,
		STORE.externalControlPanels,
		STORE.userInfo
	)
)(withRouter(AlarmList));
