import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
	// load translation using http
	.use(Backend)
	// detect user language
	.use(LanguageDetector)

	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options

	.init({
		debug: true,
		fallbackLng: 'en',
		supportedLngs: ['sv', 'en'],
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		resources: {
			en: {
				translation: {
					generic: {
						loading: 'Loading...',
						dayOrPeriod_one: '{{count}} day/period',
						dayOrPeriod_other: '{{count}} days/periods',
						removed_other: 'Removed',
						city: 'City',
						city_other: 'Cities',
						address: 'Address',
						name: 'Name',
						group: 'Group',
						label: 'Label',
						description: 'Description',
						type: 'Type',
						user: 'User',
						user_lowercase: 'user',
						date: 'Date',
						object: 'Object',
						attribute: 'Attribute',
						value: 'Value',
						comment: 'Comment',
						alarmPeriod_other: 'Alarmperiods',
						cancel: 'Cancel',
						save: 'Save',
						remove: 'Remove',
						alarm_one: 'Alarm',
						alarm_other: 'Alarms',
						bms: 'BAS',
						acknowledged_one: 'Acknowledged',
						acknowledge: 'Acknowledge',
						confirm: 'Confirm',
						holiday: 'Holiday',
						select: 'Select',
						selected: 'Selected',
						to_lowercase: 'to',
						noPropOption: '-- No property --',
						camera_one: 'Camera',
						camera_other: 'Cameras',
						property: 'Property',
						leave: 'Leave',
						end: 'End',
						hour_other: 'Hours',
						deviation_other: 'Remarks',
						created: 'Created',
						electricity_lowercase: 'electricity',
						water_lowercase: 'water',
						districtHeating_lowercase: 'dist. heating',
						per: 'per',
						hour: 'Hour',
						day: 'Day',
						week: 'Week',
						month: 'Month',
						year: 'Year',
						fiveYears: '5years',
						download: 'Download',
						email: 'Email',
						confirmEmail: 'Confirm email',
						frequency: 'Frequency',
						edit: 'Edit',
						id: 'ID',
						unit: 'Unit',
						class: 'Class',
						multiplier: 'Multiplier',
						all: 'All',
						title: 'Title',
						userName: 'Username',
						firstName: 'First name',
						lastName: 'Surname',
						phoneNumber: 'Phone\xa0number',
						privilige_one: 'Privilege',
						privilige_other: 'Privileges',
						password: 'Password',
						phone: 'Phone',
						back: 'Back',
						time: 'Time',
						yes: 'Yes',
						no: 'No',
						multipleSensors: 'Multiple sensors',
						multipleLocations: 'Multiple locations',
						loadingUsers: 'Loading users...',
						loadingSensors: 'Loading sensors...',
						loadingSensorGroups: 'Loading sensor groups...',
						loadingBuildings: 'Loading buildings...',
						loadingCOs: 'Loading control-objects...',
						loading3DViews: 'Loading 3D-views...',
						loadingCameras: 'Loading cameras...',
						closeDocument: 'Close document',
						openFullscreen: 'Open in fullscreen',
						report: 'Report-',
						customerName: 'Customer name',
						system: 'System',
						communicationType: 'Communication type',
						minimum: 'Minimum',
						maximum: 'Maximum',
						sensorType: 'Sensor type',

						//aiControl: 'AI Sensors/Tags',
						aiControl: 'Settings',
						aiControl_other: 'AI Controls',
						sensorsAndTags: 'Sensors and tags',
						tableSelTitle: 'Selected : {0}',

						recipients: 'Recipients',
						recipient: 'Recipient',
						reportName: 'Report Name',
						pages: 'Pages',

						controlObject: 'Control-object',
						supplyTemp: 'Supply temperature',
						outdoorTemp: 'Outdoor temperature',
						electricity: 'Electricity',
						indoorTemp: 'Indoor temperature',
						utilitySensors: 'Utility sensors',
						curveChange: 'Curve change',
					},
					constants: {
						overview: 'Overview',
						properties: 'Buildings',
						map: 'Map',
						alarms: 'Alarms',
						deviations: 'Remarks',
						reports: 'Reports',
						actions: 'Events',
						analysis: 'Analysis',
						errorReporting: 'Error reporting',
						caseManagement: 'Case management',
						automation: 'AI Control',
						administration: 'Administration',
						charts: 'Analysis',

						indoorTemperature: 'Indoor temperature',
						outdoorTemperature: 'Outdoor temperature',
						subcategory: 'Subcategory',
						includeInTotal: 'Include in total',
						accumulatedData: 'Accumulated data',

						meters: 'Meters',
						alarmHistory: 'Alarms',
						threeSixty: '3D-Views',
						externalControlPanels: 'BAS',
						cos: 'Control-objects',
						opcard: 'Sys.-diag.',
						documents: 'Documents',

						personal: 'User',
						users: 'Other users',
						sensorSubscriptions: 'Subscriptions',
						sensors: 'Sensors',
						sensorGroups: 'Groups',
						controlObjects: 'Control-objects',
						threeSixtyModels: '3D-Views',
						cameras: 'Cameras',
						wizards: 'Wizards',

						selectTimeRange: 'Select Time Range',
						selectUpdateInterval: 'Select Update Interval',
						includeInReport: 'Include In Report',
						exampleReport: 'Example Report',
						selectProperties: 'Select Properties',
						createGraph: 'Create Graph',
						propertiesToolbar: 'Properties',
						normalizeData: 'Relative Scale',
						fullscreen: 'Fullscreen Mode',
						editMode: 'Edit Mode',
						widgets: 'Widgets',

						currentPeriod: 'Current period',
						formerPeriod: 'Former period',

						day: 'Day',
						week: 'Week',
						month: 'Month',
						year: 'Year',
						fiveYears: '5years',

						electricity: 'Electricity',
						water: 'Water',
						districtHeating: 'District heating',
						units: 'Units',
						energyConsumption: 'Energy consumption',
						other: 'Other',
						manual: 'Manual',
						photo: 'Photo',
						operationCard: 'System diagram',
						setPoint: 'Set value',
						curve: 'Curve',

						classification: 'Classification',
						lowerThreshhold: 'Lower threshold',
						upperThreshhold: 'Upper threshold',
						sensorGroup: 'Sensor group',
						alarmDelayMinutes: 'Alarm delay minutes', // TODO: Check translation
						flyInInfo: 'Fly-in info point',
						flyInCameraPos: 'Fly-in kamera position',
						alarmPeriods: 'Alarm periods',
						cadastral: 'Building ID',
						squareMetres: 'Square metres',
						longitude: 'Longitude',
						latitude: 'Latitude',
						digitalTwin: 'Digital twin',
						title: 'Title',
						mediaLink: 'Media link',
						xPos: 'X position',
						yPos: 'Y position',
						zPos: 'Z position',
						xAngle: 'X angle',
						yAngle: 'Y angle',
						zAngle: 'Z angle',
						deleting: 'Deletion', // TODO: Double check this one with context
						deletingDesc: 'Allow deletion of object.',
						receipt: 'Kvittering', // TODO: Need context for this one
						receiptDesc: 'Allow acknowledgment of alarms and remarks.',
						editAlarmThreshhold: 'Editing of alarm threshold', // TODO: Shaky translation
						editAlarmThreshholdDesc: 'Allow changes to alarm tresholds.', // TODO: Could be editing instead of changes
						tableHeader: 'Table header',

						perProptertyConsumption: 'Per-property consumption',
						electricityConsumptionComp: 'Electricity consumption comparison',
						waterConsumptionComp: 'Water consumption comparison',
						districtHeatingConsumptionComp: 'District heating consumption comparison',

						sensorEdited: 'Sensor edited',
						sensorGroupEdited: 'Sensorgroup edited',
						propertyEdited: 'Property edited',
						fileEdited: 'File edited',
						digitalTwinEdited: 'Digital twin edited',
						infoPointEdited: 'Info point edited',
						coEdited: 'Control-object edited',
						coRegisterEdited: 'Control-object register edited',
						deviationEdited: 'Remark edited',
						receiptEdited: 'Receipt edited',

						sensorGroupAdded: 'Sensor group added',
						propertyAdded: 'Property added',
						fileAdded: 'File added',
						digitalTwinAdded: 'Digital twin added',
						infoPointAdded: 'Info point added',
						cameraPosAdded: 'Camera position added',
						coAdded: 'Control-object added',
						deviationAdded: 'Remarks added',

						fileDeleted: 'File deleted',
						infoPointDeleted: 'Info point deleted',
						multiplier: 'Multiplier',

						includeCharts: 'Analysis page',
						includeAlarms: 'Alarms',
						includeAI: 'Ai report',

						curve: 'Curve',
						indoorsensor: 'Indoor temp',
						energyPerformance: 'Energy performance',
						results: 'Energy performance',
						yourProperties: 'Your properties',

						mean: 'Mean',
						sum: 'Sum',
						raw: 'Raw',
						interval: 'Interval',
					},
					genericAdmin: {
						editSelected: 'Edit selected',
						deleteSelected: 'Delete selected',
						selectPropsPrompt: 'Select buildings...',
						withoutName: 'without name',
						selectValuesFor: 'Enter new values for',
						archiveSelected: 'Delete selected',
						exportExcel: 'Export to Excel',
						addSensor: 'Add sensor',
					},
					app: { failed: 'Failed to authenticate user.', loading: 'Authentication in progress...' },
					actionList: {
						digitalTwinTagWithoutTitle: 'Tag without title',
						actionTitle: 'Events last 48 hours',
					},
					alarmEditor: {
						editThresholdsTitle: 'Edit thresholds for <0>{{sensorName}}</0>',
						lowerThresholdAbb: 'Lower thr.',
						upperThresholdAbb: 'Upper thr.',
						graceMinsAbb: 'Grace min.',
						unsaved_other: 'Unsaved',
						activated_other: 'Activated',
						noneSpecified: 'None specified',
						editAlarmPeriods: 'Edit alarm-periods',
					},
					alarmList: {
						tableTitle: 'Alarms (all buildings)',
						ackSelectedCount_one: 'Acknowledge {{count}} selected alarm',
						ackSelectedCount_other: 'Acknowledge {{count}} selected alarms',
						alarmValue: 'Alarm-value',
						alarmTime: 'Alarm-time',
						showAcked: 'Show acknowledged',
						confirmAck: 'Confirm acknowledgement',
						addComment: 'Add comment',
						ackComment: 'Acknowledgement-comment',
						earliestAlarmValue: 'Tidigaste larmvärde:',
						highestAlarmValue: 'Högsta larmvärde:',
						latestAlarmValue: 'Senaste larmvärde:',
						earliestAlarm: 'Tidigaste larm: ',
					},
					alarmPeriodEditor: {
						monday_initial: 'M',
						tuesday_initial: 'T',
						wednesday_initial: 'W',
						thursday_initial: 'T',
						friday_initial: 'F',
						saturday_initial: 'S',
						sunday_initial: 'S',
						inclusive: 'Inclusive',
						exclusive: 'Exclusive',
						inclusiveDesc: 'Alarms will only be triggered during the periods specified below.',
						exclusiveDesc: 'Alarms will be triggered at all times except during the periods specified below.',
						deletePeriod: 'Delete alarm-period',
						addPeriod: 'Add period',
					},
					cameraAdmin: {
						tableSelTitle: 'Selected cameras: {0}',
						addCamera: 'Add camera',
						filterCameras: 'Filter cameras',
						selectedCameras: 'selected cameras.',
						theSelectedCamera_one: 'the selected camera.',
						theSelectedCamera_other: 'the selected cameras.',
						editCamera: 'Edit camera',
						addNewValuesToSelectedCamera: 'Add new values to the selected camera.',
						RTSPAddress: 'RTSP-address',
						cameraInfo: 'Camera information has been updated',
						cameraAdded: 'Camera has been added',
					},
					coAdmin: {
						amountOfRegisters: 'Amount of Registers', // TODO: Check if it's ok that this generates two rows.
						ucAddress: 'UC Address', //TODO: Check that this is the correct translation in english
						createNewControlObject: 'Create new control-object', // TODO: Check if it's ok that this generates two rows.
						filterControlObjects: 'Filter control-objects:',
						editControlObjects: 'Edit control-objects.',
						selectedControlObjects: 'selected control-objects.',
						theSelectedCO_one: 'the selected control-object.',
						theSelectedCO_other: 'the selected control-objects',
						selectValuesForNewCO: 'Ange värden för det nya styrobjektet.',
						amountSelectedCOs: 'Number of selected control-objects: ',
						coInfo: 'Control-object information has been updated',
						coDeleted: 'Control-object has been deleted',
						coAdded: 'Control-object has been added',
					},
					confirmPopup: {
						confirmPrompt: 'Confirm your choice.',
						confirmsubtext: 'Changes are not saved.',
					},
					coView: {
						offset: 'Offset',
						incorrectOffset: 'Incorrect offset',
						endOffsetTitle: 'End offset',
						endOffsetSubtitle: 'End offset prematurely?',
						offsetTitle: 'Offset control-object register',
						offsetSubtitle: 'Specify offset-amount and duration.',
						flowTempAbb_lowercase: 'flow temp.',
						unit_other_lowercase: 'units',
						improperlyConfiguredRegs: 'Incorrectly configured register.',
						point: 'Point',
						setpoint: 'Setpoint',
						curveYLabel: 'Flow temp. (C)',
						curveXLabel: 'Outdoor temp. (C)',
					},
					coRegisterAdmin: {
						CORegister: 'Control-object registers',
						nSelectedRegisters: 'Number of selected registers',
						selectedRegisters: 'selected registers',
						importSensors: 'Import sensors',
						filterRegisters: 'Filter registers',
						register_lowercase: 'register',
						theSelectedRegister_one: 'the selected register.',
						theSelectedRegister_other: 'the selected registers.',
						nSelectedSensors: 'Number of selected sensors',
					},
					createdUserAdmin: {
						selectedProperty_lowercase: 'selected property',
						selectedProperties_lowercase: 'selected buildings',
						amountOfSelectedUsers: 'Amount of selected users: ',
						createNewUser: 'Create new user.',
						editUser: 'Edit user',
						theSelectedUser_one: 'the selected user',
						theSelectedUser_other: 'the selected users',
						selectedUsers: 'selected users.',
						provideNewValuesForNewUser: 'Provide values for the new user.',
						numberSelectedProperties: 'Number of selected buildings',
						cadastral: 'Building ID', // TODO: Check that this is the correct translation in english
						squareMetres: 'Square metres',
						selectPriviliges: 'Select priviliges', // TODO: Check that this is the correct context in english
						nSelectedPriviliges: 'Number of selected priviliges',
						selectDot: 'Select...',
						currentAndFuturePromt: 'All current and future.', // TODO: Feels like this one could be better
						readOnly: 'Read only.', // TODO: This feels like the best translation but really needs to be checked as this kinda says uneditable
						ackAndTreshEdit: 'Receipt and alarm threshold editing.', // TODO: Is Receipt the correct translation?
						admin: 'Admin',
						deleteSelectedUsers: 'Confirm deletion of selected users',
						areYouSureYouWantToDelete: 'Confirm that the selected user should be deleted.',
					},
					deviationList: {
						tableSelTitle_one: 'Acknowledge {{count}} selected remark',
						tableSelTitle_other: 'Acknowledge {{count}} selected remarks',
						showDtConnection: 'Show 3D-connection',
						addDeviation: 'Add remark',
						addDeviationSubtitle: 'Assign information to the new remark.',
						ackDeviations: 'Acknowledge remark',
						ackComment: 'Acknowledgement-comment',
					},
					documentView: {
						uploadDocument: 'Upload document',
						nameDocument: 'Name the new document.',
						upload: 'Upload', // TODO: Check context. This could also be "Send" or "Submit".
						onlyPDF: 'Only PDF-files are allowed.',
						fileTooLarge: 'The file is too large. Max 10MB.',
					},
					documentList: {
						document: 'Document',
						added: 'Added',
						by: 'By',
						editDocument: 'Edit document',
						saveChanges: 'Save changes',
						deleteDocument: 'Delete document',
						confirmRemoval: 'Confirm removal',
					},
					ducSelection: {
						selectDUC: 'Select BAS...',
						selectControlObject: 'Select control-object...',
						pageTitle: 'Select BAS and control-object',
						curvesFound: 'Found {{xCurves}} X-curves and {{yCurves}} Y-curves for the selected control-object.',
						noCurvesFound:
							'No X-curves or Y-curves found for the selected control-object. Try another control-object or contact support.',
					},
					indoorTemperatureSelection: {
						pageTitle: 'Adjust the indoor temperature',
						temperatureRange: 'Temperature range',
						temperatureRangeInfo: 'A larger temperature range will result in less energy consumption. {{min}}°C ~ {{max}}°C',
						indoorTempSensors: 'Indoor temperature sensors',
						maxTemperature: 'Max temperature',
						minTemperature: 'Min temperature',
					},
					utilitySensors: {
						pageTitle: 'Select utility sensors',
						sensorsNotFound: 'The following sensor types were not found for the selected property: {{sensors}}.',
					},
					energySensorSelector: {
						energyType: 'Energy type',
						energySensor: 'Energysens.',
					},
					externalControlPanelAdmin: {
						DUCS: 'Building-Automation-System', //TODO: Check translation
						amountChosenDucs: 'Number of selected BASs: ',
						IP: 'IP',
						port: 'Port',
						ducID: 'BAS\xa0ID',
						register: 'Register',
						url: 'URL',
						addDuc: 'Add BAS',
						filterDucs: 'Filter BASs:',
						editDuc_one: 'Edit BAS',
						editDuc_other: 'Edit {{count}} selected BASs', // TODO: This could be translated more elegantly.
						cadastral: 'Name', // This is repeat, unsure if it's best practice to repeat translations.
						enterValuesForNewDuc: 'Provide values for the new BAS.',
						ducAdded: 'BAS has been added',
						ducInfo: 'BAS information has been updated',
						newValuesForSelectedDuc: 'Enter new values for the selected BAS.',
						newValuesForSelectedDuc_other: 'Enter new values for the selected BASs.',
					},
					externalControlPanel: {
						verificationNeeded: 'To proceed, a verification step is required. Please click the button to verify.',
						clickToVerify: 'Click to verify',
						bmsOpenNewWindowPromt: 'This BAS needs to be opened in a new window.',
						openBms: 'Open BAS',
					},
					nivoCharts: {
						kilo: 'k',
						mega: 'M',
						giga: 'G',
					},
					automation: {
						pageTitle: 'AI Control',
						effect: 'Effect',
						optimizationType: 'Optimization-type',
						minimizeConsumption: 'Minimize overall consumption',
						minimizePeaks: 'Minimize peaks',
						optimizeWater: 'Optimize water-systems',
						insideTempThresholds: 'Indoor temperature-tresholds',
						lowerThresh: 'Lower thresholds:',
						upperThresh: 'Upper thresholds:',
						selectedProps: 'Selected buildings',
					},
					importedRegisterList: {
						registerToImport: 'Register to import', // Plural?
						editRegister: 'Edit register',
						group: 'Group',
						parameter: 'Parameter',
						readWrite: 'Read/Write',
						importRegister: 'Import register', // Plural?
						saveChanges: 'Save changes',
					},
					materialTableWrapper: {
						search: 'Search',
						rows: 'rows',
						firstPage: 'First page',
						previousPage: 'Previous page',
						nextPage: 'Next page',
						lastPage: 'Last page',
						displayedRows: '{{from}}-{{to}} of {{count}}',
					},
					meterCard: {
						updated: 'Updated:',
						thresholds: 'Threshhold values:',
					},
					mostAlarmsChart: {
						mostAlarms: 'Alarms',
						onAveragePerProp: 'On average {{count}} per property.',
						alarm: 'alarms',
						perProperty: 'By property', // TODO: Could be per aswell
						perSquareMetre: 'By m²', // TODO: Could be per aswell
					},
					operationCardView: {
						uploadOperationCard: 'Upload system diagram',
						nameOperationCard: 'Name the new system diagram.',
						upload: 'Upload', // TODO: Check context. This could also be "Send" or "Submit".
						saveSettings: 'Save settings',
						removeOperationCard: 'Remove system diagram',
						confirmRemoval: 'Confirm removal',
						editOperationCard: 'Edit system diagram',
					},
					overviewChart: {
						districtHeating: 'District heating',
						water: 'Water',
						electricity: 'Electricity',
						currency: '$', // This is a repeat, maybe centralize?
					},
					propertyAdmin: {
						numberSelectedProperties: 'Number of selected buildings: ', // This is mostly a repeat, maybe centralize?
						cadastral: 'Building ID', // This is a repeat, unsure if it's best practice to repeat translations.
						squareMetres: 'Square metres', // This is a repeat
						numberOfSensors: 'Sensor count',
						createNewProp: 'Create new property',
						createNewPropDot: 'Create new property.',
						editSelected: 'Edit selected',
						deleteSelected: 'Delete selected',
						filterProp: 'Filter buildings:',
						selectCitiesDot: 'Select cities...',
						editProperies_one: 'Edit property {{street}}',
						editProperies_other: 'Edit {{count}} selected building.',
						giveNewValuesProp_one: 'Enter new values for the selected building.',
						giveNewValuesProp_any: 'Enter new values for the selected buildings.',
						giveNewValuesNewProp: 'Enter values for the new property.',
						longitude: 'Longitude',
						latitude: 'Latitude',
						confirmDeleted: 'Confirm deletion',
						confirmPropDelete_one: 'Confirm that the selected property should be delted.',
						confirmPropDelete_any: 'Confirm that the {{count}} selected buildings should be deleted.',
						propertyInfo: 'Property information has been updated',
						propertyDeleted: 'Property has been deleted',
						propertyAdded: 'Property has been added',
					},
					propertyList: {
						squareMetres: 'Square metres',
						propertyDesignation: 'Property designation',
						search: 'Search',
						rows: 'rows',
						firstPage: 'First page',
						previousPage: 'Previous page',
						nextPage: 'Next page',
						lastPage: 'Last page',
						displayedRows: '{{from}}-{{to}} of {{count}}',
						selectAll: 'Select all',
						alarm: 'Alarms',
						propertyLoading: 'Loading properties...',
					},
					propertySelection: {
						multiple: 'properties',
						all: 'all properties',
						selectProperties: 'Select properties',
					},
					sensorGraph: {
						timePeriod: 'Time period',
						moveTimePeriod: 'Move time period',
						compare: 'Compare',
						alarmThreshhold: 'Alarm threshold',
					},
					sensorGroupCard: {
						updated: 'Updated:',
						numberOfSensors: 'Sensor count:', // TODO: Could be meters or gauges? unsure
						latestService: 'Last service occasion:', //TODO: Unsure how i feel about the word "occasion"
						nextService: 'Next service occasion:', //TODO: Same here
						hideSensor: 'Hide sensor', // TODO: Could be meter or gauges? unsure
						showSensor: 'Show sensor', // TODO: Same here
						average: 'Average:',
					},
					sensorGroupAdmin: {
						sensorGroups: 'Groups',
						numberSelectedGroups: 'Number of selected groups: ',
						numberOfSensors: 'Number of sensors',
						createNewGroup: 'Create new group', // TODO: Could be Create a new group
						createNewGroupDot: 'Create new group.', // TODO: Same here
						editGroup: 'Edit group ',
						withoutName: 'without name.',
						editSelectedGroups: 'Edit {{length}} selected groups.',
						enterNewValuesForGroup_one: 'Enter new values for the selected group.',
						enterNewValuesForGroup_any: 'Enter new values for the selected groups.',
						selectValuesNewGroup: 'Enter values for the new group.',
						confirmDeletion: 'Confirm deletion',
						confirmDeletePromt_one: 'Confirm that the selected group should be deleted.',
						confirmDeletePromt_any: 'Confirm that the selected {{count}} groups should be deleted.',
						filterGroups: 'Filter groups:',
						selectProperties: 'Select buildings...',
						sensorGroupInfo: 'Sensor group information has been updated',
						sensorGroupDeleted: 'Sensor group has been deleted',
						sensorGroupAdded: 'Sensor group has been added',
						sensorAdded: 'Sensor has been added',
						addSensor: 'Add sensor',
						enterValuesForSensor: 'Enter values for the new sensor.',
					},
					sensorList: {
						status: 'Status',
						alarmThreshholds: 'Alarm thresholds',
						stopSubscribing: 'Stop subscribing',
						startSubscribing: 'Start subscribing',
						show3dConnection: 'Show 3D-connection', //TODO: Check translation. Unsure what "koppling" means in this context.
						sensorMissing: 'Sensor missing.', //TODO: Could also be "Sensor not found." or "Missing sensor."
						latestTimestamp: 'Last updated',
					},
					sensorAdmin: {
						registeredSensors: 'Registered sensors',
						numberOfSelectedSensors: 'Number of selected sensors: ',
						latestUpdated: 'Latest updated',
						filterSensors: 'Filter sensors:',
						selectProperties: 'Select buildings...',
						nonRegisteredSensors: 'Non-registered sensors (does not belong to any property)',
						selectKeyword: 'Select keyword...',
						editSensors: 'Edit sensor ',
						edit: 'Edit ',
						selectedSensors: ' selected sensors',
						selectValuesNewSensorPromt_one: 'Enter new values for the selected sensor.',
						selectValuesNewSensorPromt_any: 'Enter new values for the selected sensors.',
						alarmDelayMinutes: 'Alarm delay minutes', //TODO: Very shaky translation
						alarmPeriods: 'Alarm periods',
						unsavedChanges: 'Unsaved changes', // TODO: could be "Unsaved settings"?
						activated: 'Activated',
						activatedCertainSensors: 'Activated for some sensors',
						noneSpecified: 'None specified',
						noProperty: '-- No property --',
						group: 'Group',
						noGroup: '-- No group --',
						editAlarmPeriods: 'Edit alarm periods',
						confirmDeletion: 'Confirm deletion',
						confirmDeletePromt_one: 'Confirm that the selected sensor should be deleted.',
						confirmDeletePromt_any: 'Confirm that the selected {{count}} sensors should be deleted.',
						sensorInfo: 'Sensor information has been updated',
						sensorDeleted: 'Sensor has been deleted',
						sensorRef: 'Sensor reference',

						temperature: 'Temperature',
						indoor: 'Indoor',
						outdoor: 'Outdoor',
						supply_air: 'Supply Air',
						exhaust_air: 'Exhaust Air',
						supply_radiator_water: 'Supply Radiator Water',
						return_radiator_water: 'Return Radiator Water',
						supply_tap_water: 'Supply Tap Water',
						return_tap_water: 'Return Tap Water',
						humidity: 'Humidity',
						relative: 'Relative',
						absolute: 'Absolute',
						energy: 'Energy',
						electricity_usage: 'Electricity Consumption',
						district_heating_usage: 'District Heating Consumption',
						general_energy_usage: 'General Energy Consumption',
						electricity_production: 'Electricity Production',
						flow: 'Flow',
						water: 'Waterflow',
						air: 'Airflow',
						pressure: 'Pressure',
						air_supply_pressure: 'Air Supply Pressure',
						air_exhaust_pressure: 'Air Exhaust Pressure',
						water_supply_pressure: 'Water Supply Pressure',
						light_and_sound: 'Light and Sound',
						sound: 'Sound',
						light: 'Light',
						fanspeed: 'Fan Speed',
						speed: 'Speed',
						status: 'Status',
						onoff: 'On/Off',
						percentage: 'Percentage',
						water_and_waste: 'Water and Waste',
						waste: 'Waste',
						hot_water: 'Hot Water',
						cold_water: 'Cold Water',
						hot_water_flow: 'Hot Water',
						cold_water_flow: 'Cold Water',
						general_water_flow: 'Water',
						alarm: 'Alarm',
						custom: 'Custom',
						sensortype: 'Category',
						sensorsubtype: 'Subcategory',
						accumulatedData: 'Accumulated Data',
						setPoint: 'Setpoint',
						includedInTotal: 'Included in Building Consumption',
					},
					sensorSubscriptionAdmin: {
						sensorSubscriptions: 'Sensor subscriptions',
						numberOfSelectedSubscriptions: 'Number of selected Subscriptions: ',
						latestValue: 'Latest value',
						latestUpdated: 'Latest updated',
						stopSubscribingSelected: 'Stop subscribing to selected',
						filterSubscriptions: 'Filter Subscriptions:',
						selectPropsDot: 'Select buildings...',
						stopSubscribingPromt_one: 'Do you want to stop subscribing to the selected sensor?', // TODO: Grammar feels shaky here. Maybe rephrase?
						stopSubscribingPromt_other: 'Do you want to stop subscribing to the selected sensors?',
					},
					staticTimeRange: {
						today: 'Today',
						yesterday: 'Yesterday',
						thisWeek: 'This Week',
						lastWeek: 'Last Week',
						thisMonth: 'This Month',
						lastMonth: 'Last Month',
					},
					tabCreation: {
						nameTab: 'What is the name of your new tab?',
						createTabButton: 'Create',
						newTabTitle: 'New tab',
					},
					tabDeletion: {
						deleteConfirmationTitle: 'Delete Tab',
						deleteConfirmationText: 'Are you sure you want to delete',
						deleteConfirmationLabel: 'Enter tab name',
						deleteConfirmationCancel: 'Cancel',
						deleteConfirmationDelete: 'Delete',
					},
					widgetDeletion: {
						deleteConfirmationTitle: 'Delete Widget',
						deleteConfirmationText: 'Are you sure you want to delete this widget?',
						deleteConfirmationCancel: 'Cancel',
						deleteConfirmationDelete: 'Delete',
					},
					widgetPopoutMenu: {
						currentTitle: 'Current title',
						changeNameSuccess: 'Title successfully changed!',
						inputNewGraphTitleText: 'Input new title for the graph',
						datePickerStartDate: 'Start date',
						datePickerEndDate: 'End date',
						changeTitle: 'Change Title',
					},
					trendsChart: {
						worstEnergyTrend: 'Negative energy trends',
						bestEnergyTrend: 'Positive energy trends',
						duringLatest: 'During the latest {{interval}}.', // TODO: Check translation and context
					},
					threeSixtyAdmin: {
						noProperty: '-- No property --',
						dViews: '3D-Views', // TODO: This variable name is horrible but "3" is not allowed in the beginning of names.
						numberOfSelectedViews: 'Number of selected views: ',
						numberOfInfoPoints: 'Number of info points',
						addView: 'Add view',
						addViewDot: 'Add view.',
						filterViews: 'Filter views:',
						selectProperties: 'Select buildings...',
						editView: 'Edit view ',
						withoutName: 'without name.',
						editSelectedViews: 'Edit {{count}} selected views.',
						enterNewValuesForView_one: 'Enter new values for the selected view.',
						enterNewValuesForView_any: 'Enter new values for the selected views.',
						enterValuesNewView: 'Enter values for the new view.',
						idOrUrl: 'ID eller URL-link',
						viewInfo: 'View information has been updated',
						viewAdded: 'View has been added',
					},
					threeSixtyView: {
						title: 'Title',
						textContents: 'Text contents',
						mediaLink: 'Media link',
						selectedSensor_one: 'selected sensor',
						selectedSensor_any: 'selected sensors',
						selectedSensorGroup_one: 'selected sensor group',
						selectedSensorGroup_any: 'selected sensor groups',
						selectedDeviation_one: 'selected remark',
						selectedDeviation_any: 'selected remarks',
						createFlyIn: 'Create fly-in pos. for sensors, groups and remarks', // TODO: Make sure remarks is appropriate.
						namelessInfoPoint: 'Nameless info point',
						selectByClick3dView: 'Select info point by clicking on one in the 3D-view.',
						holdMouseStillToSelect: 'Hold your cursor still on the position you want to select.',
						addInfoPoint: 'Add info point',
						editInfoPoint: 'Edit info point',
						deleteInfoPoint: 'Delete info point',
						choosePosition: 'Choose position',
						pickAnotherPosition: 'Pick another position',
						saveFlyInPos: 'Save fly-in position',
						cameraPos: 'Camera position',
						infoPoint: 'Info point',
						group: 'Group',
						selectSensors: 'Select sensors',
						numberOfSelectedSensors: 'Number of selected sensors: ',
						selectSensorGroups: 'Select sensor groups',
						numberOfSelectedSensorGroups: 'Number of selected sensor groups: ',
						sensorCount: 'Number of sensors',
						selectDeviations: 'Select remarks',
						numberOfSelectedDeviations: 'Number of selected remarks: ',
						status: 'Status',
						average: 'Average:',
						updated: 'Updated:',
						thresholds: 'Threshold values:',
						created: 'Created:',
						acknowledged: 'Acknowledged:',
					},
					timeSelect: {
						chipPrefix: 'Time range',
						custom: 'Custom',
						day: 'Last day',
						week: 'Last week',
						month: 'Last month',
						year: 'Last year',
					},
					charts: {
						fetchingData: 'Loading sensor data...',
						fetchingError: 'Error fetching sensor data:',
						currentSensor: 'Current sensor: {{sensor}}',
						addNewWidget: 'Add',
						editModeButton: 'Edit',
						doneButton: 'Done',
						analysisLoadingMsg: 'Loading Analysis page',
						maxSensorCount: 'This component allows {{count}} selected sensors.',
						locationsInUse: 'Locations and cities that are included in graphs cannot be deselected.',
						noSensorData: 'No sensor data is available for this timespan.',
						gridFull:
							'Could not add a new widget, the maximum height of the dashboard is reached. Try moving or deleting some widgets and try again.',
					},
					lineChart: {
						defaultTitle: 'New Line Chart',
						lineChart: 'Line Chart',
					},
					barChart: {
						defaultTitle: 'New Bar Chart',
						barChart: 'Bar Chart',
					},
					pieChart: {
						defaultTitle: 'New Pie Chart',
						pieChart: 'Pie Chart',
					},
					speedoMeter: {
						defaultTitle: 'New Speedometer',
						speedoMeter: 'Speedometer',
					},
					chartsToolbar: {
						changesMadeAlert: 'You have unsaved graph changes. The changes are saved when you toggle "Edit Mode"!',
						triedToExitAlert: 'You have unsaved changes. Toggle "Edit Mode" to save your changes!',
					},
					sensorComponent: {
						// Since a few of the sesor components that we made shares lingo,
						// all their translations were merged into one
						fetchingData: 'Loading sensor data...',
						loading: 'Loading',
						errorMessage: 'Error: {{error}}',
						fetchError: 'Error fetching data: {{error}}. Please refresh the page',
						numSensors: '{{count}} sensors',
						sensorSelect: 'Sensor Selection',
					},
					userAdmin: {
						electricityPerKWH: 'Electricity per kWh',
						waterPerCM: 'Water per m³',
						districtHeatingPerKWH: 'District heating per kWh',
						faultyValuesError: 'Could not save due to faulty values.',
						changePassword: 'Change password',
						alarmNotifications: 'Alarm notifications',
						energyPrices: 'Energy-prices in $',
						sms: 'Sms',
						DUCAlarm: 'BAS alarm',
						provideNewPasswordUser: 'Enter your current and new password.',
						newPassword: 'New password',
						confirmNewPassword: 'Confirm new password',
						//newPasswordNotSame: 'The entered passwords are not the same.', // TODO: Can be phrased better.
						UpdateUserDetails: 'Update information',
						cancelled: 'Cancelled!',
						cancelPassword: 'The password has not been changed',
						cancelInfo: 'Information has been not changed',
						saveInfo: 'Information has been updated',
						savePassword: 'Password has been updated',
						success: 'Success!',
						createUser: 'User has been created',
						deleteUser: 'User has been deleted',
						newPasswordSame: 'The new password is the same as the old one.',
						currentPasswordNotSame: 'The current password is not correct.',
						currentPassword: 'Current password',
						newPasswordNotSame: 'New and confirm password mismatch.',
						userAlreadyExists: 'User already exists.',
						userNameOrEmailAlreadyExists: 'Username or email already exists.',
						emailAddressNotMatched: 'Email address does not match.',
						firstNameOrLastNameMissing: 'First name and last name are required.',
					},
					overview: {
						kr: '$',
						tkr: 'k $',
						of_lowercase: 'of',
						allProps_lowercase: 'all buildings',
						selectedCities_lowercase: 'selected cities',
						selectedProps_lowercase: 'selected buildings',
						selectedSens_lowercase: 'selected sensors',
						connectedProps: 'Connected buildings',
						alarmCount: 'Overall alarms',
						notAckedAlarmCount: 'Alarms not acknowledged',
						noticeCount: 'Remarks',
						cumulativeConsumptionFor: 'Cumulative consumption for',
						duringLast: 'during the last',
						energyPricesTitle: 'Energy-prices in $:',
						loadingMsg: 'Preparing overview-page...',
					},
					reports: {
						generateForHeader: 'Generate report for:',
						otherInterval: 'Other interval',
						failedMsg: 'Failed to generate report.',
						loadingMsg: 'Generating report...',
						errorOccurred: 'An error occurred.',
						couldNotGenerate: 'The report could not be generated.',
						couldNotGenerateAlert: 'The {{file}} file could not be generated.',
						csvIncludeProperties: 'Include sensors in the sensor table.',
						reportRecipients: 'Report-recipients',
						addRecipient: 'Add recipient',
						removeRecipient: 'Remove recipient',
						extraPagesHeader: 'Included extra-pages:',
						changeExtraPages: 'Change selection of extra-pages',
						includedSensors: 'Included sensors',
						includedProperties: 'Included properties',
						changeSensors: 'Change selection of sensors',
						changeProperties: 'Change selection of properties',
						selectTimeIntervals: 'Select time intervals', // TODO: Check for plural or singular
						selectTimeIntervalsMsg: 'From which time interval should the report be generated?', //TODO: Can be worded better with more context
						generate: 'Generate',
						beginning: 'Beginning',
						end: 'End',
						addRecipientMsg: "What is the new recipient's email address and how often will they receive a report?",
						selectExtraPages: 'Select extra pages', //TODO: Unsure what "extrasidor" means, leaving this one for now.
						page: 'Page',
						selectSensorsReport: 'Select sensors', //TODO: Need to see this one in the website to translate.
						numberSelectedSensors: 'Number of selected sensors: ',
						selectPropertiesReport: 'Select properties',
						numberSelectedProperties: 'Number of selected properties: ',
						confirmRemovalUser: 'Confirm removal of user', //TODO: user could be recipient
						reportsNotSentTo: 'Reports will no longer be sent to ',
						confirmChangeSensors: 'Confirm change of sensors',
						confirmChangeProperties: 'Confirm change of properties',
						addedSensor_one: ' added sensor',
						addedSensor_any: ' added sensors',
						addedProperties_any: ' added properties',
						and: ' and ',
						removedSensor_one: ' removed sensor',
						removedSensor_any: ' removed sensors',
						removedProperties_any: ' removed properties',
						noChangesFound: 'No changes could be found.',
						group: 'Group',

						deleteReportTitle: 'Delete report',
						deleteReportText: 'Are you sure you want to delete the following report?',
						createPdfButton: 'PDF Report',
						createCsvButton: 'CSV Report',
						reportsTableTitle: 'Your Reports',
						turnOffDeliver: 'Turn off report delivery',
						turnOnDeliver: 'Turn on report delivery',
						createReport: 'Create report',
						chooseRecipients: 'Choose recipients',
						saveRecipient: 'Save recipient',
						saveReport: 'Save report',
						confirmSaveTitle: 'Are you sure you want to save the changes?',
						confirmSaveMessage: 'Press confirm, the changes will be saved in the report.',
					},
					reportPopups: {
						report: 'Report',
						reportSettingsTitle: 'Configure Report',
						pagesTitle: 'Included Pages',
						propertiesTitle: 'Properties',
						sensorsTitle: 'Individual Sensors',
						quickGenerateButton: 'Quick Generate',
						subscribeButton: 'Subscribe Report',
						noPageSelectedError: 'Please select atleast one page to include in the report.',
						wrongEmailFormatError: 'The email adress entered is on an invalid format.',
						noIntervalsSelectedError: 'Please select atleast one interval to include in the report.',
						noReportNameError: 'Please enter a name for the report.',
						defaultReportNameWarning:
							'You are using the default report name. Change it to something more descriptive or press "{{buttonName}}" again to continue.',
						recipientInProgressError:
							'A recipient is not saved. Press "{{addRecipient}} to save or "{{buttonName}}" again to continue without a recipient.',
						addRecipient: '+ save recipient',
						noSensorsSelectedError: 'Please select atleast one sensor to include in the report.',
						noSensorsCsvError: 'Please select atleast one sensor in order to generate a CSV file.',
						csvDownloadError: 'An error occurred while downloading the CSV file. Try again.',
						csvDownloadButton: 'Download CSV',
						noPropertySelectedError: 'Please select atleast one property to include in the report.',
					},
					maps: {
						propertyFilter: 'Property filter:',
						onlyAlarmed: 'Only alarmed:', // TODO: Phrasing chould be better.
						alarms: 'Alarms:',
						additionalAlarms: 'more alarms.',
					},
					registrationWizard: {
						import: 'Import',
						sensorGroups: 'Sensor groups',
						IP: 'IP',
						port: 'Port',
						ducID: 'BAS-ID',
						url: 'URL',
						noCitySelected: 'no city selected',
						noAddressSelected: 'no address selected',
						existingProperty: 'Existing property', // TODO: Could be available?
						selectProperty: 'Select a property...',
						newProperty: 'New property',
						cadastral: 'Building ID',
						squareMetres: 'Square metre',
						longitude: 'Longitude',
						latitude: 'Latitude',
						existingDUC: 'Existing BAS',
						selectDuc: 'Select BAS...',
						importDucPromt: 'In order for the BAS file to be imported, the IP, port and BAS ID must be specified',
						unknownFileTypePromt: 'Unknown file type, could not be uploaded.',
						nonRegisteredSensors: 'Unregistered sensors', // TODO: Could be non registered sensors?
						numberOfSelectedSensors: 'Number of selected sensors: ',
						sensorsPromt: 'Sensors: ',
						deleteSensorGroup: 'Delete sensor group',
						addSensorGroup: 'Add sensor group',
						selected: ' Selected', // TODO: Check source file for explination
						existing: 'existing',
						new: 'new',
						freestandingSensors: 'Sensors (standalone)',
						ducSensors: 'BAS Sensors',
						readWrite: '(read/write)',
						read: '(read)',
						write: '(write)',
						continue: 'Continue',
						change: 'Change', // TODO: Could be edit?
						upload: 'Upload',
						success: 'Property information has been updated',
						utilitySensors: 'Utility sensors',
						supplyTemp: 'Supply temp.',
						outdoorTemp: 'Outdoor temp.',
						electricity: 'Electricity',
						selectSensor: 'Select sensor',
						modbus: 'Modbus',
						aiControl: 'AI control has been added',
						curve: 'Curve',
						indoorTempSensor: 'Choose Indoor temp. sensors',
						thresholds: 'Thresholds',
					},

					toolbar: {
						create: 'Create',
						fullscreen: 'Fullscreen',
						timeInterval: 'Timespan',
						updateRate: 'Update interval',
						editMode: 'Edit',
						normalize: 'Relative Scale',
					},
					wizardAdmin: {
						propertyAndSensors: 'Property + Sensors',
						DUCImport: 'BAS Import',
					},
					importAdmin: {
						change: 'Change', //TODO: Could be edit?
						upload: 'Upload',
						DUC: 'BAS:',
						file: 'File:',
						unkownFilePromt: 'Unknown filetype, could not upload.',
						registersAdded: 'Registers have been added',
					},
					notify: {
						notifications: 'Notifications',
						logOut: 'Log out',
						userInfo: 'User information',
						userName: 'Username',
						phoneNumber: 'Phone number',
						whatsNew: 'Release Notes',
						changeLogs: 'Release Notes',
					},
					changelog: {
						updatedAt: 'Revised:',
					},
					notificationList: {
						new: 'New',
						thresholdValues: 'Threshold values',
						timestamp: 'Timestamp',
						notificationAlarmsTitle: 'Notifications (alarms on subscribed sensors)',
					},
					analysis: {
						optimization: 'Optimization',
						prediction: 'Prediction', // TODO: Check translation and context
						machineLearning: 'Machine learning',
						deepAnalysis: 'Deep analysis',
						regressionAnalysis: 'Regression analysis',
						temperature: 'Temperature',
						temperatureTrend: 'Temperature trend',
						energy: 'Energy',
						topConsumer: 'Top consumer',
						latest24h: 'Latest 24 hours',
						siteUnavailable: 'Site unavailable',
						siteNotInSubscription: 'This site is currently not in your subscription.', // TODO: This could be worded better.
					},
					modalSettings: {
						previousYear: 'Previous Year',
						currentYear: 'Current year',
						previousMonth: 'Previous Month',
						sameMonthPreviousYear: 'Same Month, Previous Year',
						previousWeek: 'Previous Week',
						previous: 'Prev.',
					},
					assignments: {
						underConstruction: 'In development.',
					},
					autoUpdate: {
						chipPrefix: 'Auto update interval',
						off: 'Off',
						minutes5: 'Every 5 minutes',
						minutes10: 'Every 10 minutes',
						minutes15: 'Every 15 minutes',
						minutes30: 'Every 30 minutes',
						hours1: 'Every hour',
						hours2: 'Every 2 hours',
						hours4: 'Every 4 hours',
						hours24: 'Every day',
					},
					issues: {
						underConstruction: 'In development.',
					},
					links: {
						linkNotFound: 'Link could not be found.',
					},
					IELandingPage: {
						browserNotSupported: 'Your browser is not supported.',
						hi: 'Hi!',
						doesNotSupportPromt: `Unfortunately, this tool does not support your browser Internet Explorer.
						If you have Microsoft Edge installed on your machine, please switch to that browser and then come back.
						Otherwise, tap the link below to download and install Microsoft Edge.`,
						pressToDownload: 'Click here to download Microsoft Edge.',
					},
					automations: {
						name: 'Name',
						system: 'System',
						communicationType: 'Communication type',
						minimum: 'Minimum',
						maximum: 'Maximum',
						sensorType: 'Sensor type',
						value: 'Value',
						automationInfo: 'Automation information has been updated',
						sensor_or_tag: 'Sensor/Tag',
						selectedSensorsOrTags: 'Selected sensors/tags',
						sensor: 'Sensor',
						addAIcontrol: 'Add AI control',
						delete: 'Delete',
						deleteSelectedSensorsOrTags: 'Confirm deletion of selected sensors/tags',
						deletedSensorsTags: 'Sensors/tags have been deleted',
						addedAIcontrol: 'AI control has been added',
						addSensor: 'Add sensor',
						sensor: 'Sensor',
						modbus: 'Modbus',
						supplyTemp: 'Supply temperature',
						outdoorTemp: 'Outdoor temperature',
						electricity: 'Electricity',
						indoorTemp: 'Indoor temperature',
						ducid: 'BAS ID',
						aiSetupError: 'There was an error when we tried to set up the AI control.',
						curveChange: 'Curve change',
						averageIndoorTemperture: 'Average indoor temperature',
					},
					aiOverview: {
						reportFor: 'Report for',
						energySaved: 'Energy saved',
						energyConsumed: 'Total consumption',
						guageTitle: 'Average indoor temperature',
						energySavedInfo: '*A decrease of 1°C is equal to 5% energy savings (-5%)',
						energyConsumedInterval: 'Total consumption on the current interval',
						// Errors
						requiresCurveChange: 'Requires curve change sensors',
						requiresDistrictHeating: 'Requires district heating sensor, add one in the sensor settings',
						noSensorOfThisType: 'No sensors of this type has been selected',
						dataFetchError: 'There was an error fetching the data, try reloading the page',
						// Curve graph
						optimized: 'Optimized curve',
						original: 'Original curve',
						min: 'Min. curve',
						max: 'Max. curve',
					},
					companyName: {
						bastec: 'Bastec Cirrus',
						meliox: 'Meliox Connect',
						swegon: 'Swegon INSIDE Director',
						sandbackens: 'Sandbäckens Connect',
					},
					loginLink: {
						bastec: 'https://bastec.meliox.se/',
						meliox: 'https://connect.melioxdev.se/',
						swegon: 'https://swegon.meliox.se/',
						sandbackens: 'https://connect.meliox.se/',
					},
					welcomeMessage: {
						welcomeMessageBody:
							"Dear {{customerName}},\n\nWelcome to {{companyName}}! We are thrilled to have you join us in optimizing your building's management and energy efficiency through our advanced cloud-based platform.\n\nTo get started, we have created your account with the following details:\nUsername: {{username}}\nPassword: {{password}}\n\nPlease click on the following link to log in to your {{companyName}} account: {{loginLink}}\n\nWith {{companyName}}, you gain access to a comprehensive suite of tools for:\n- Monitoring and managing your building's technical systems and sensors.\n- Analyzing data to optimize energy consumption and reduce costs.\n- Streamlining facility and building management across your property portfolio.\n\nThank you for choosing {{companyName}}. Together, let's make your buildings smarter and more sustainable.\n\nBest regards,\nTeam {{companyName}}",
						welcomeMessageSubject: 'Welcome to {{companyName}}',
					},
					welcomeSpecial: {
						welcomeMessageBody:
							"Dear {{customerName}},\n\nWelcome to {{companyName}}! We are thrilled to have you join us in optimizing your building's management and energy efficiency through our advanced cloud-based platform.\n\nTo get started, we have created your account with the following details:\nUsername: {{username}}\nPassword: {{password}}\n\nPlease click on the following link to log in to your {{companyName}} account: {{loginLink}}\n\nWith {{companyName}}, you gain access to a comprehensive suite of tools for:\n- Monitoring and managing your building's technical systems and sensors.\n- Analyzing data to optimize energy consumption and reduce costs.\n- Streamlining facility and building management across your property portfolio.\n\nIf you have any questions or need assistance, please do not hesitate to contact us at {{contactEmail}}.\n\nThank you for choosing {{companyName}}. Together, let's make your buildings smarter and more sustainable.\n\nBest regards,\nTeam {{companyName}}",
						welcomeMessageSubject: 'Welcome to {{companyName}}',
					},

					supportEmail: {
						bastec: 'support@bastec.se',
						meliox: 'support@meliox.se',
						swegon: 'support@swegon.se',
						sandbackens: 'support@sandbackens.se',
					},
				},
			},
			sv: {
				translation: {
					generic: {
						loading: 'Laddar...',
						dayOrPeriod_one: '{{count}} dag/period',
						dayOrPeriod_other: '{{count}} dagar/perioder',
						removed_other: 'Borttagna',
						city: 'Stad',
						city_other: 'Städer',
						address: 'Adress',
						name: 'Namn',
						group: 'Grupp',
						label: 'Benämning',
						description: 'Beskrivning',
						type: 'Typ',
						user: 'Användare',
						user_lowercase: 'användare',
						date: 'Datum',
						object: 'Objekt',
						attribute: 'Egenskap',
						value: 'Värde',
						comment: 'Kommentar',
						alarmPeriod_other: 'Larmperioder',
						cancel: 'Avbryt',
						save: 'Spara',
						remove: 'Ta bort',
						alarm_one: 'Larm',
						alarm_other: 'Larm',
						bms: 'DUC',
						acknowledged_one: 'Kvitterad',
						acknowledge: 'Kvittera',
						confirm: 'Bekräfta',
						holiday: 'Helgdag',
						select: 'Välj',
						selected: 'Markerade',
						to_lowercase: 'till',
						noPropOption: '-- Ingen fastighet --',
						camera_one: 'Kamera',
						camera_other: 'Kameror',
						property: 'Fastighet',
						leave: 'Lämna',
						end: 'Avsluta',
						hour_other: 'Timmar',
						deviation_other: 'Avvikelser',
						created: 'Skapad',
						electricity_lowercase: 'elektricitet',
						water_lowercase: 'vatten',
						districtHeating_lowercase: 'fjärrvärme',
						per: 'per',
						hour: 'Timme',
						day: 'Dag',
						week: 'Vecka',
						month: 'Månad',
						year: 'År',
						fiveYears: '5år',
						download: 'Ladda ner',
						title: 'Titel',
						email: 'Email',
						confirmEmail: 'Bekräfta email',
						frequency: 'Frekvens',
						edit: 'Redigera',
						id: 'Id',
						unit: 'Enhet',
						class: 'Klass',
						multiplier: 'Multiplikator',
						all: 'Alla',
						userName: 'Användarnamn',
						firstName: 'Förnamn',
						lastName: 'Efternamn',
						phoneNumber: 'Telefonnummer',
						privilige_one: 'Rättighet',
						privilige_other: 'Rättigheter',
						password: 'Lösenord',
						phone: 'Telefon',
						back: 'Tillbaka',
						time: 'Tid',
						yes: 'Ja',
						no: 'Nej',
						multipleSensors: 'Flera sensorer',
						multipleLocations: 'Flera fastigheter',
						loadingUsers: 'Laddar användare...',
						loadingSensors: 'Laddar sensorer...',
						loadingSensorGroups: 'Laddar sensorgrupper...',
						loadingBuildings: 'Laddar fastigheter...',
						loadingCOs: 'Laddar styrobjekt...',
						loading3DViews: 'Laddar 3D-vyer...',
						loadingCameras: 'Laddar kameror...',
						closeDocument: 'Stäng dokument',
						openFullscreen: 'Öppna i helskärm',
						report: 'Rapport-',
						customerName: 'Kundnamn',
						system: 'System',
						communicationType: 'Kommunikationstyp',
						minimum: 'Minimum',
						maximum: 'Maximum',
						sensorType: 'Sensortyp',

						//aiControl: 'AI Sensorer/Taggar',
						aiControl: 'Inställningar',
						aiControl_other: 'AI Styrning',
						sensorsAndTags: 'Sensorer och taggar',
						tableSelTitle: 'Selected : {0}',

						// reports
						recipients: 'Mottagare',
						recipient: 'Mottagare',
						reportName: 'Rapportnamn',
						pages: 'Sidor',

						controlObject: 'Styrobjekt',
						supplyTemp: 'Framledningstemperatur',
						outdoorTemp: 'Utomhustemperatur',
						electricity: 'Elektricitet',
						indoorTemp: 'Inomhustemperatur',
						utilitySensors: 'Mätsensorer',
						curveChange: 'Kurvändring',
					},
					constants: {
						overview: 'Översikt',
						properties: 'Fastigheter',
						map: 'Karta',
						alarms: 'Larm',
						deviations: 'Avvikelser',
						analysis: 'Analys',
						reports: 'Rapporter',
						actions: 'Händelser',
						errorReporting: 'Felanmälan',
						caseManagement: 'Ärendehantering',
						automation: 'AI Styrning',
						administration: 'Administration',
						charts: 'Analys',

						indoorTemperature: 'Inomhustemperatur',
						outdoorTemperature: 'Utomhustemperatur',
						subcategory: 'Underkategori',
						includeInTotal: 'Inkludera i total byggnadsförbrukning',
						accumulatedData: 'Ackumulerad data',

						meters: 'Mätare',
						alarmHistory: 'Larm',
						threeSixty: '3D-Vyer',
						externalControlPanels: 'DUC',
						cos: 'Styrobjekt',
						opcard: 'Driftkort',
						documents: 'Document',

						personal: 'Användare',
						users: 'Andra användare',
						sensorSubscriptions: 'Prenumerationer',
						sensors: 'Sensorer',
						sensorGroups: 'Grupper',
						controlObjects: 'Styrobjekt',
						threeSixtyModels: '3D-Vyer',
						cameras: 'Kameror',
						wizards: 'Assistenter',

						currentPeriod: 'Nuv. period',
						formerPeriod: 'Föreg. period',

						day: 'Dag',
						week: 'Vecka',
						month: 'Månad',
						year: 'År',
						fiveYears: '5år',

						electricity: 'El',
						water: 'Vatten',
						districtHeating: 'Fjärrvärme',
						units: 'Enheter',
						energyConsumption: 'Energiförbrukning',
						other: 'Annat',
						manual: 'Manual',
						photo: 'Foto',
						operationCard: 'Driftkort',
						setPoint: 'Börvärde',
						curve: 'Kurva',

						classification: 'Klassifikation',
						lowerThreshhold: 'Undre tröskelvärde',
						upperThreshhold: 'Övre tröskelvärde',
						sensorGroup: 'Sensorgrupp',
						alarmDelayMinutes: 'Larmfördröjningsminuter',
						flyInInfo: 'Fly-in infopunkt',
						flyInCameraPos: 'Fly-in kameraposition',
						alarmPeriods: 'Larmperioder',
						cadastral: 'Fastighetsbeteckning',
						squareMetres: 'Kvadratmeter',
						longitude: 'Longitud',
						latitude: 'Latitud',
						digitalTwin: 'Digital tvilling',
						title: 'Titel',
						mediaLink: 'Medialänk',
						xPos: 'X-position',
						yPos: 'Y-position',
						zPos: 'Z-position',
						xAngle: 'X-vinkel',
						yAngle: 'Y-vinkel',
						zAngle: 'Z-vinkel',
						deleting: 'Raderande',
						deletingDesc: 'Tillåt raderande av objekt.',
						receipt: 'Kvittering',
						receiptDesc: 'Tillåt kvittering av larm och avvikelser.',
						editAlarmThreshhold: 'Redigerande av larmtröskel',
						editAlarmThreshholdDesc: 'Tillåt redigerande av larmtröskelvärden.',
						tableHeader: 'Tabellrubrik',

						selectTimeRange: 'Välj Tidsintervall',
						selectUpdateInterval: 'Välj Uppdateringsintervall',
						includeInReport: 'Inkludera I Rapport',
						exampleReport: 'Exempelrapport',
						selectProperties: 'Välj Fastigheter',
						createGraph: 'Skapa Graf',
						propertiesToolbar: 'Fastigheter',
						normalizeData: 'Relativ Skala',
						fullscreen: 'Helskärmsläge',
						editMode: 'Redigeringsläge',
						widgets: 'Diagram',

						perProptertyConsumption: 'Per-fastighetsförbrukning',
						electricityConsumptionComp: 'Elförbrukningsjämförelse',
						waterConsumptionComp: 'Vattenförbrukningsjämförelse',
						districtHeatingConsumptionComp: 'Fjärrvärmejämförelse',

						sensorEdited: 'Sensor redigerad',
						sensorGroupEdited: 'Sensorgrupp redigerad',
						propertyEdited: 'Fastighet redigerad',
						fileEdited: 'Fil redigerad',
						digitalTwinEdited: 'Digital tvilling redigerad',
						infoPointEdited: 'Infopunkt redigerad',
						coEdited: 'Styrobjekt redigerad',
						coRegisterEdited: 'Styrobjektsregister redigerad',
						deviationEdited: 'Avvikelse redigerad',
						receiptEdited: 'Kvittering redigerad',

						sensorGroupAdded: 'Sensorgrupp tillagd',
						propertyAdded: 'Fastighet tillagd',
						fileAdded: 'Fil tillagd',
						digitalTwinAdded: 'Digital tvilling tillagd',
						infoPointAdded: 'Infopunkt tillagd',
						cameraPosAdded: 'Kameraposition tillagd',
						coAdded: 'Styrobjekt tillagd',
						deviationAdded: 'Avvikelse tillagd',

						fileDeleted: 'Fil raderad',
						infoPointDeleted: 'Infopunkt raderad',
						multiplier: 'Multiplikator',

						includeCharts: 'Analyssida',
						includeAlarms: 'Alarm',
						includeAI: 'Ai rapport',

						curve: 'Kurva',
						indoorsensor: 'Inomhusgivare',
						energyPerformance: 'Energiprestanda',
						results: 'Energiprestanda',
						yourProperties: 'Dina fastigheter',

						mean: 'Medelvärde',
						sum: 'Summering',
						raw: 'Rådata',
						interval: 'Intervall',
					},
					genericAdmin: {
						editSelected: 'Redigera valda',
						deleteSelected: 'Radera valda',
						selectPropsPrompt: 'Välj fastigheter...',
						withoutName: 'utan namn.',
						selectValuesFor: 'Ange nya värden för',
						archiveSelected: 'Ta bort valda',
						exportExcel: 'Exportera till Excel',
						addSensor: 'Lägg till sensor',
					},
					app: { failed: 'Användarautentisering misslyckades.', loading: 'Autentisering pågår...' },
					actionList: {
						digitalTwinTagWithoutTitle: 'Infopunkt utan titel',
						actionTitle: 'Händelser senaste 48 timmar',
					},
					alarmEditor: {
						editThresholdsTitle: 'Redigera tröskelvärden för <0>{{sensorName}}</0>',
						lowerThresholdAbb: 'Undre larm',
						upperThresholdAbb: 'Övre larm',
						graceMinsAbb: 'Fördröj.min.',
						unsaved_other: 'Osparade',
						activated_other: 'Aktiverade',
						noneSpecified: 'Inga angivna',
						editAlarmPeriods: 'Redigera larmperioder',
					},
					alarmList: {
						tableTitle: 'Larm (alla fastigheter)',
						ackSelectedCount_one: 'Kvittera {{count}} valt larm',
						ackSelectedCount_other: 'Kvittera {{count}} valda larm',
						alarmValue: 'Larmvärde',
						alarmTime: 'Larmtid',
						showAcked: 'Visa kvitterade',
						confirmAck: 'Bekräfta kvitterande',
						addComment: 'Lägg till kommentar',
						ackComment: 'Kvitteringskommentar',
					},
					alarmPeriodEditor: {
						monday_initial: 'M',
						tuesday_initial: 'T',
						wednesday_initial: 'O',
						thursday_initial: 'T',
						friday_initial: 'F',
						saturday_initial: 'L',
						sunday_initial: 'S',
						inclusive: 'Inkluderande',
						exclusive: 'Exkluderande',
						inclusiveDesc: 'Larm kommer endast gå under de nedan valda larmperioderna.',
						exclusiveDesc: 'Larm kommer gå under alla tider förutom de nedan valda larmperioderna.',
						deletePeriod: 'Radera larmperiod',
						addPeriod: 'Lägg till period',
					},
					cameraAdmin: {
						tableSelTitle: 'Antal valda kameror: {0}',
						addCamera: 'Lägg till kamera.',
						filterCameras: 'Filtrera kameror',
						selectedCameras: 'valda kameror.',
						theSelectedCamera_one: 'den valda kameran.',
						theSelectedCamera_other: 'de valda kamerorna.',
						editCamera: 'Redigera kamera',
						addNewValuesToSelectedCamera: 'Ange nya värden för den valda kameran.',
						RTSPAddress: 'RTSP-adress',
						cameraInfo: 'Kamerainformation har uppdaterats',
						cameraAdded: 'Kamera har lagts till',
					},
					confirmPopup: {
						confirmPrompt: 'Bekräfta ditt val.',
						confirmsubtext: 'Ändringar sparas inte.',
					},
					coAdmin: {
						amountOfRegisters: 'Antal register',
						ucAddress: 'UC-adress',
						createNewControlObject: 'Skapa nytt styrobjekt',
						filterControlObjects: 'Filtrera styrobjekt:',
						editControlObjects: 'Redigera styrobjekt',
						selectedControlObjects: 'valda styrobjekt.',
						theSelectedCO_one: 'det valda styrobjektet.',
						theSelectedCO_other: 'de valda styrobjekten.',
						selectValuesForNewCO: 'Ange värden för det nya styrobjektet.',
						amountSelectedCOs: 'Antal valda styrobjekt: ',
						coInfo: 'Styrobjektinformation har uppdaterats',
						coDeleted: 'Styrobjekt har raderats',
						coAdded: 'Styrobjekt har lagts till',
					},
					coView: {
						offset: 'Förskjutning',
						incorrectOffset: 'Felaktig förskjutning',
						endOffsetTitle: 'Avsluta förskjutning',
						endOffsetSubtitle: 'Avsluta förskjutning i förtid?',
						offsetTitle: 'Förskjut styrobjektsregister',
						offsetSubtitle: 'Ange förskjutningsvärde och tid.',
						flowTempAbb_lowercase: 'framledningstemp.',
						unit_other_lowercase: 'enheter',
						improperlyConfiguredRegs: 'Felaktigt konfigurerade register.',
						point: 'Punkt',
						setpoint: 'Börvärde',
						curveYLabel: 'Framledningstemp. (C)',
						curveXLabel: 'Utetemp. (C)',
					},
					coRegisterAdmin: {
						CORegister: 'Styrobjektsregister',
						nSelectedRegisters: 'Antal valda register',
						selectedRegisters: 'valda register',
						importSensors: 'Importera sensorer',
						filterRegisters: 'Filtrera register',
						register_lowercase: 'register',
						theSelectedRegister_one: 'det valda registret.',
						theSelectedRegister_other: 'de valda registren.',
						nSelectedSensors: 'Antal valda sensorer',
					},
					createdUserAdmin: {
						selectedProperty_lowercase: 'vald fastighet',
						selectedProperties_lowercase: 'valda fastigheter',
						amountOfSelectedUsers: 'Antal valda användare: ',
						createNewUser: 'Skapa ny användare.',
						editUser: 'Redigera användare',
						theSelectedUser_one: 'den valda användaren.',
						theSelectedUser_other: 'de valda användarna.',
						selectedUsers: 'valda användare.',
						provideNewValuesForNewUser: 'Ange värden för den nya användaren.',
						numberSelectedProperties: 'Antal valda fastigheter',
						cadastral: 'Fastighetsbeteckning',
						squareMetres: 'Kvadratmeter',
						selectPriviliges: 'Välj rättigheter',
						nSelectedPriviliges: 'Antal valda rättigheter',
						selectDot: 'Välj...',
						currentAndFuturePromt: 'Alla befintliga och framtida.',
						readOnly: 'Bara läs.',
						ackAndTreshEdit: 'Kvittering och larmtröskelredigering.',
						admin: 'Admin',
						deleteSelectedUsers: 'Bekräfta borttagning av valda användare',
						areYouSureYouWantToDelete: 'Bekräfta att den valda användaren ska tas bort',
					},
					deviationList: {
						tableSelTitle_one: 'Kvittera {{count}} vald avvikelse',
						tableSelTitle_other: 'Kvittera {{count}} valda avvikelser',
						showDtConnection: 'Visa 3D-koppling',
						addDeviation: 'Lägg till avvikelse',
						addDeviationSubtitle: 'Ange värden för den nya avvikelsen.',
						ackDeviations: 'Kvittera avvikelser',
						ackComment: 'Kvitteringskommentar',
					},
					documentView: {
						uploadDocument: 'Ladda upp dokument',
						nameDocument: 'Namnge det nya dokumentet.',
						upload: 'Ladda upp',
						onlyPDF: 'Endast PDF-filer tillåtna.',
						fileTooLarge: 'Filen är för stor. (Max 10MB)',
					},
					documentList: {
						document: 'Dokument',
						added: 'Tillagt',
						by: 'Av',
						editDocument: 'Redigera dokument',
						saveChanges: 'Spara ändringar',
						deleteDocument: 'Radera dokument',
						confirmRemoval: 'Bekräfta bortagning',
					},
					ducSelection: {
						selectDUC: 'Välj en DUC...',
						selectControlObject: 'Välj ett styrobjekt...',
						pageTitle: 'Välj DUC och styrobjekt',
						curvesFound: 'Hittade {{xCurves}} X-kruvor och {{yCurves}} Y-kurvor för det valda styrobjektet.',
						noCurvesFound:
							'Inga kurvor hittades för det valda styrobjektet. Prova ett annat styrobjekt eller kontakta support.',
					},
					indoorTemperatureSelection: {
						pageTitle: 'Justera inomhustemperaturen',
						temperatureRange: 'Temperaturintervall',
						temperatureRangeInfo: 'Ett större temperaturinterval innebär lägre energiförbrukning. {{min}}°C ~ {{max}}°C',
						indoorTempSensors: 'Inomhustemperatursensorer',
						maxTemperature: 'Maxtemperatur',
						minTemperature: 'Mintemperatur',
					},
					utilitySensors: {
						pageTitle: 'Välj mätsensorer',
						sensorsNotFound: 'Inga sensorer tillhörande fastigheten hittades för följande sensortyper: {{sensors}}.',
					},
					energySensorSelector: {
						energyType: 'Energityp',
						energySensor: 'Energisens.',
					},
					externalControlPanelAdmin: {
						DUCS: 'Dataundercentraler',
						amountChosenDucs: 'Antal valda DUCer: ',
						IP: 'IP',
						port: 'Port',
						ducID: 'DUC-ID',
						register: 'Register',
						url: 'URL',
						addDuc: 'Lägg till DUC',
						filterDucs: 'Filtrera DUCer:',
						editDuc_one: 'Redigera DUC',
						editDuc_other: 'Redigera {{count}} valda DUCer',
						newValuesForSelectedDuc: 'Ange nya värden för den valda DUCen.',
						newValuesForSelectedDuc_other: 'Ange nya värden för de valda DUCerna.',
						cadastral: 'Benämning', // This is repeat, unsure if it's best practice to repeat translations.
						enterValuesForNewDuc: 'Ange värden för den nya DUCen.',
						ducAdded: 'DUC har lagts till',
						ducInfo: 'DUC-information har uppdaterats',
					},
					externalControlPanel: {
						verificationNeeded: 'För att fortsätta krävs ett verifieringssteg. Klicka på knappen för att verifiera.',
						clickToVerify: 'Klicka för att verifiera',
						bmsOpenNewWindowPromt: 'Denna DUC behöver öppnas i ett nytt fönster.',
						openBms: 'Öppna DUC',
					},
					nivoCharts: {
						kilo: 't',
						mega: 'mn',
						giga: 'md',
					},
					automation: {
						pageTitle: 'AI Styrning',
						effect: 'Effekt',
						optimizationType: 'Optimeringsslag',
						minimizeConsumption: 'Minimera totalförbrukning',
						minimizePeaks: 'Minimera energitoppar',
						optimizeWater: 'Optimera vattensystem',
						insideTempThresholds: 'Innetemperaturtröskelvärden',
						lowerThresh: 'Undre tröskel:',
						upperThresh: 'Övre tröskel:',
						selectedProps: 'Valda fastigheter',
					},
					importedRegisterList: {
						registerToImport: 'Register att importera',
						editRegister: 'Redigera register',
						group: 'Grupp',
						parameter: 'Parameter',
						readWrite: 'Läs/Skriv',
						importRegister: 'Importera register',
						saveChanges: 'Spara ändringar',
					},
					materialTableWrapper: {
						search: 'Sök',
						rows: 'rader',
						firstPage: 'Första sida',
						previousPage: 'Föregående sida',
						nextPage: 'Nästa sida',
						lastPage: 'Sista sida',
						displayedRows: '{{from}}-{{to}} av {{count}}',
					},
					meterCard: {
						updated: 'Uppdaterad:',
						thresholds: 'Tröskelvärden:',
					},
					mostAlarmsChart: {
						mostAlarms: 'Flest larm',
						onAveragePerProp: 'I genomsnitt {{count}} per fastighet.',
						alarm: 'larm',
						perProperty: 'Per fastighet',
						perSquareMetre: 'Per m²',
					},
					operationCardView: {
						uploadOperationCard: 'Ladda upp driftkort',
						nameOperationCard: 'Namnge det nya driftkortet.',
						upload: 'Ladda upp',
						saveSettings: 'Spara ändringar',
						removeOperationCard: 'Radera driftkort',
						confirmRemoval: 'Bekräfta borttagning',
						editOperationCard: 'Redigera driftkort',
					},
					overviewChart: {
						districtHeating: 'Fjärrvärme',
						water: 'Vatten',
						electricity: 'El',
						currency: 'kr', // This is a repeat, maybe centralize?
					},
					propertyAdmin: {
						numberSelectedProperties: 'Antal valda fastigheter: ', // This is mostly a repeat, maybe centralize?
						cadastral: 'Fastighetsbeteckning', // This is repeat, unsure if it's best practice to repeat translations.
						squareMetres: 'Kvadratmeter', // This is repeat, unsure if it's best practice to repeat translations.
						numberOfSensors: 'Antal sensorer',
						createNewProp: 'Skapa ny fastighet',
						createNewPropDot: 'Skapa ny fastighet.',
						editSelected: 'Redigera valda',
						deleteSelected: 'Radera valda',
						filterProp: 'Filtrera fastigheter:',
						selectCitiesDot: 'Välj städer...',
						editProperies_one: 'Redigera fastighet {{street}}',
						editProperies_other: 'Redigera {{count}} valda fastigheter.',
						giveNewValuesProp_one: 'Ange nya värden för den valda fastigheten.',
						giveNewValuesProp_any: 'Ange nya värden för de valda fastigheterna.',
						giveNewValuesNewProp: 'Ange värden för den nya fastigheten.',
						longitude: 'Longitud',
						latitude: 'Latitud',
						confirmDeleted: 'Bekräfta raderande',
						confirmPropDelete_one: 'Bekräfta att den valda fastigheten skall raderas.',
						confirmPropDelete_any: 'Bekräfta att de valda {{count}} fastigheterna skall raderas.',
						propertyInfo: 'Fastighetsinformationen har uppdaterats',
						propertyDelete: 'Fastigheten har raderats',
						propertyAdded: 'Fastigheten har lagts till',
					},
					propertyList: {
						squareMetres: 'Kvadratmeter',
						propertyDesignation: 'Fastighets beteckning', // TODO: Spacing between words?
						search: 'Sök',
						rows: 'rader',
						firstPage: 'Första sida',
						previousPage: 'Föregående sida',
						nextPage: 'Nästa sida',
						lastPage: 'Sista sida',
						displayedRows: '{{from}}-{{to}} av {{count}}',
						selectAll: 'Välj alla',
						alarm: 'Larm',
						propertyLoading: 'Fastighetsinformation laddas...',
					},
					propertySelection: {
						multiple: 'fastigheter',
						all: 'samtliga fastigheter',
						selectProperties: 'Välj fastigheter',
					},
					sensorGraph: {
						timePeriod: 'Tidsperiod',
						moveTimePeriod: 'Flytta tidsperiod',
						compare: 'Jämför',
						alarmThreshhold: 'Larmtröskel',
					},
					sensorGroupCard: {
						updated: 'Uppdaterad:',
						numberOfSensors: 'Antal mätare:',
						latestService: 'Senaste servicetillfälle:',
						nextService: 'Nästa servicetillfälle:',
						hideSensor: 'Göm mätare',
						showSensor: 'Visa mätare',
						average: 'Medelvärde:',
					},
					sensorGroupAdmin: {
						sensorGroups: 'Sensor-grupper',
						numberSelectedGroups: 'Antal valda grupper: ',
						numberOfSensors: 'Antal sensorer',
						createNewGroup: 'Skapa ny grupp',
						createNewGroupDot: 'Skapa ny grupp.',
						editGroup: 'Redigera grupp ',
						withoutName: 'utan namn.',
						editSelectedGroups: 'Redigera {{length}} valda grupper.',
						enterNewValuesForGroup_one: 'Ange nya värden för den valda gruppen.',
						enterNewValuesForGroup_any: 'Ange nya värden för de valda grupperna.',
						selectValuesNewGroup: 'Ange värden för den nya gruppen.',
						confirmDeletion: 'Bekräfta raderande',
						confirmDeletePromt_one: 'Bekräfta att den valda gruppen skall raderas.',
						confirmDeletePromt_any: 'Bekräfta att de valda {{count}} grupperna skall raderas.',
						filterGroups: 'Filtrera grupper:',
						selectProperties: 'Välj fastigheter...',
						sensorGroupInfo: 'Sensorgruppsinformationen har uppdaterats',
						sensorGroupDelete: 'Sensorgruppen har raderats',
						sensorGroupAdded: 'Sensorgruppen har lagts till',
						sensorAdded: 'Sensorn har lagts till',
						addSensor: 'Lägg till sensor',
						enterValuesForSensor: 'Ange värden för den nya sensorn.',
					},
					sensorList: {
						status: 'Status',
						alarmThreshholds: 'Larmtrösklar',
						stopSubscribing: 'Sluta prenumerera',
						startSubscribing: 'Börja prenumerera',
						show3dConnection: 'Visa 3D-koppling',
						sensorMissing: 'Mätare saknas.',
						latestTimestamp: 'Senast uppdaterad',
					},
					sensorAdmin: {
						registeredSensors: 'Registrerade sensorer',
						numberOfSelectedSensors: 'Antal valda sensorer: ',
						latestUpdated: 'Senast uppdaterad',
						filterSensors: 'Filtrera sensorer:',
						selectProperties: 'Välj fastigheter...',
						nonRegisteredSensors: 'Oregistrerade sensorer (tillhör inga fastigheter)',
						selectKeyword: 'Välj nyckelord...',
						editSensors: 'Redigera sensor ',
						edit: 'Redigera ',
						selectedSensors: ' valda sensorer',
						selectValuesNewSensorPromt_one: 'Ange nya värden för den valda sensorn.',
						selectValuesNewSensorPromt_any: 'Ange nya värden för de valda sensorerna.',
						alarmDelayMinutes: 'Larmfördröjningsminuter', //TODO: Don't know how to translate this one "Alarm delay minutes"?
						alarmPeriods: 'Larmperioder',
						unsavedChanges: 'Osparade ändringar',
						activated: 'Aktiverade',
						activatedCertainSensors: 'Aktiverade för vissa sensorer',
						noneSpecified: 'Inga angivna',
						noProperty: '-- Ingen fastighet --',
						group: 'Grupp',
						noGroup: '-- Ingen grupp --',
						editAlarmPeriods: 'Redigera larmperioder',
						confirmDeletion: 'Bekräfta raderande',
						confirmDeletePromt_one: 'Bekräfta att den valda sensorn skall raderas.',
						confirmDeletePromt_any: 'Bekräfta att de valda {{count}} sensorerna skall raderas.',
						sensorInfo: 'Sensorinformationen har uppdaterats',
						sensorDeleted: 'Sensorn har raderats',
						sensorRef: 'Sensorreferens',

						temperature: 'Temperatur',
						indoor: 'Inomhus',
						outdoor: 'Utomhus',
						supply_air: 'Tilluft',
						exhaust_air: 'Frånluft',
						supply_radiator_water: 'Radiator Tillförselvatten',
						return_radiator_water: 'Radiator Returvatten',
						supply_tap_water: 'Kran Tillförselvatten',
						return_tap_water: 'Kran Returvatten',
						humidity: 'Luftfuktighet',
						relative: 'Relativ',
						absolute: 'Absolut',
						energy: 'Energi',
						electricity_usage: 'Elförbrukning',
						district_heating_usage: 'Fjärrvärmeförbrukning',
						general_energy_usage: 'Allmän Energiförbrukning',
						electricity_production: 'Elproduktion',
						flow: 'Flöde',
						water: 'Vattenflöde',
						air: 'Luftflöde',
						pressure: 'Tryck',
						air_supply_pressure: 'Lufttillförseltryck',
						air_exhaust_pressure: 'Luftavgastryck',
						water_supply_pressure: 'Vattentillförseltryck',
						light_and_sound: 'Ljus och Ljud',
						sound: 'Ljud',
						light: 'Ljus',
						fanspeed: 'Fläkthastighet',
						speed: 'Hastighet',
						status: 'Status',
						onoff: 'På/Av',
						percentage: 'Procent',
						water_and_waste: 'Vatten och avfall',
						waste: 'Avfall',
						hot_water: 'Varmvatten',
						cold_water: 'Kallvatten',
						hot_water_flow: 'Varmvatten',
						cold_water_flow: 'Kallvatten',
						general_water_flow: 'vatten',
						alarm: 'Larm',
						custom: 'Övrig',
						sensortype: 'Kategori',
						sensorsubtype: 'Underkategori',
						accumulatedData: 'Ackumulerad Data',
						setPoint: 'Börvärde',
						includedInTotal: 'Inkluderas i Totalförbrukning',
					},
					sensorSubscriptionAdmin: {
						sensorSubscriptions: 'Sensorprenumerationer',
						numberOfSelectedSubscriptions: 'Antal valda prenumerationer: ',
						latestValue: 'Senaste värde',
						latestUpdated: 'Senast uppdaterad',
						stopSubscribingSelected: 'Sluta prenumerera på valda',
						filterSubscriptions: 'Filtrera prenumerationer:',
						selectPropsDot: 'Välj fastigheter...',
						stopSubscribingPromt_one: 'Vill du sluta prenumera på följande sensor?', // TODO: Grammar feels shaky here. Maybe rephrase?
						stopSubscribingPromt_other: 'Vill du sluta prenumera på följande sensorer?',
					},
					staticTimeRange: {
						today: 'Idag',
						yesterday: 'Igår',
						thisWeek: 'Nuvarande Vecka',
						lastWeek: 'Förra Veckan',
						thisMonth: 'Nuvarnade Månad',
						lastMonth: 'Förra Månaden',
					},
					tabCreation: {
						nameTab: 'Vad vill du döpa din nya flik till?',
						createTabButton: 'Skapa',
						newTabTitle: 'Ny flik',
					},
					tabDeletion: {
						deleteConfirmationTitle: 'Radera flik',
						deleteConfirmationText: 'Är du säker på att du vill radera',
						deleteConfirmationLabel: 'Ange fliknamn',
						deleteConfirmationCancel: 'Avbryt',
						deleteConfirmationDelete: 'Radera',
					},
					widgetDeletion: {
						deleteConfirmationTitle: 'Radera Widget',
						deleteConfirmationText: 'Är du säker på att du vill ta bort denna widget?',
						deleteConfirmationCancel: 'Nej',
						deleteConfirmationDelete: 'Ja',
					},
					widgetPopoutMenu: {
						currentTitle: 'Nuvarande titel:',
						changeNameSuccess: 'Titlen är nu uppdaterad!',
						inputNewGraphTitleText: 'Ange en ny titel för grafen',
						datePickerStartDate: 'Startdatum',
						datePickerEndDate: 'Slutdatum',
						changeTitle: 'Byt Namn',
					},
					trendsChart: {
						worstEnergyTrend: 'Sämsta energitrend',
						bestEnergyTrend: 'Bäst energitrend',
						duringLatest: 'Under senaste {{interval}}.',
					},
					threeSixtyAdmin: {
						noProperty: '-- Ingen fastighet --',
						dViews: '3D-Vyer', // TODO: This variable name is horrible but "3" is not allowed in the beginning of names.
						numberOfSelectedViews: 'Antal valda vyer: ',
						numberOfInfoPoints: 'Antal infopunkter',
						addView: 'Lägg till vy',
						addViewDot: 'Lägg till vy.',
						filterViews: 'Filtrera vyer:',
						selectProperties: 'Välj fastigheter...',
						editView: 'Redigera vy ',
						withoutName: 'utan namn.',
						editSelectedViews: 'Redigera {{count}} valda vyer.',
						enterNewValuesForView_one: 'Ange nya värden för den valda vyn.',
						enterNewValuesForView_any: 'Ange nya värden för de valda vyerna.',
						enterValuesNewView: 'Ange värden för den nya vyn.',
						idOrUrl: 'Id eller URL-länk',
						viewInfo: 'Vyinformationen har uppdaterats',
						viewAdded: 'Vyn har lagts till',
					},
					threeSixtyView: {
						title: 'Titel',
						textContents: 'Textinnehåll',
						mediaLink: 'Medialänk',
						selectedSensor_one: 'vald sensor',
						selectedSensor_any: 'valda sensorer',
						selectedSensorGroup_one: 'vald sensorgrupp',
						selectedSensorGroup_any: 'valda sensorgrupper',
						selectedDeviation_one: 'vald avvikelse',
						selectedDeviation_any: 'valda avvikelser',
						createFlyIn: 'Skapa fly-in pos. för sensorer, grupper och avv.', // TODO: Don't know how to translate
						namelessInfoPoint: 'Namnlös infopunkt',
						selectByClick3dView: 'Välj infopunkt genom att clicka på en i 3D-vyn.',
						holdMouseStillToSelect: 'Håll musen stilla på den position du vill välja.',
						addInfoPoint: 'Lägg till infopunkt',
						editInfoPoint: 'Redigera infopunkt',
						deleteInfoPoint: 'Radera infopunkt',
						choosePosition: 'Välj position',
						pickAnotherPosition: 'Välj annan position',
						saveFlyInPos: 'Spara fly-in position',
						cameraPos: 'Kameraposition',
						infoPoint: 'Infopunkt',
						group: 'Grupp',
						selectSensors: 'Välj sensorer',
						numberOfSelectedSensors: 'Antal valda sensorer: ',
						selectSensorGroups: 'Välj sensorgrupper',
						numberOfSelectedSensorGroups: 'Antal valda sensorgrupper: ',
						sensorCount: 'Antal sensorer',
						selectDeviations: 'Välj avvikelser',
						numberOfSelectedDeviations: 'Antal valda avvikelser: ',
						status: 'Status',
						average: 'Medelvärde:',
						updated: 'Uppdaterad:',
						thresholds: 'Tröskelvärden:',
						created: 'Skapad:',
						acknowledged: 'Kvitterad:',
					},
					timeSelect: {
						chipPrefix: 'Tidsspan',
						custom: 'Anpassad',
						day: 'Senaste dagen',
						week: 'Senaste veckan',
						month: 'Senaste månaden',
						year: 'Senaste året',
					},
					charts: {
						fetchingData: 'Hämtar sensor data...',
						fetchingError: 'Fel vid hämtning av data:',
						currentSensor: 'Nuvarande sensor: {{sensor}}',
						addNewWidget: 'Lägg till',
						editModeButton: 'Redigera',
						doneButton: 'Klar',
						analysisLoadingMsg: 'Laddar analyssida',
						maxSensorCount: 'Denna komponenten tillåter {{count}} markerade sensorer.',
						locationsInUse: 'Fastigheter och städer som tillhör existerande grafer kan inte avmarkeras.',
						noSensorData: 'Det finns ingen sensordata för detta tidsspann.',
						gridFull:
							'Det gick inte att lägga till en ny graf, höjdgränsen är nådd. Flytta runt eller ta bort några grafer och försök igen.',
					},
					lineChart: {
						defaultTitle: 'Nytt Linjediagram',
						lineChart: 'Linjediagram',
					},
					barChart: {
						defaultTitle: 'Nytt Stapeldiagram',
						barChart: 'Stapeldiagram',
					},
					pieChart: {
						defaultTitle: 'Nytt Cirkeldiagram',
						pieChart: 'Cirkeldiagram',
					},
					speedoMeter: {
						defaultTitle: 'Ny Speedometer',
						speedoMeter: 'Speedometer',
					},
					chartsToolbar: {
						changesMadeAlert: 'Du har ändrat i dina grafer. För att spara ändringarna, tryck på "Redigerinsläge" knappen!',
						triedToExitAlert:
							'Du har inte sparat dina ändringar. Tryck på knappen "Redigeringsläge" för att spara dina ändringar!',
					},
					sensorComponent: {
						fetchingData: 'Hämtar sensor data...',
						loading: 'Laddar',
						errorMessage: 'Fel: {{error}}',
						fetchError: 'Fel vid hämtning av data: {{error}}. Ladda om sidan',
						numSensors: ' sensorer',
						sensorSelect: 'Val av sensorer',
					},
					userAdmin: {
						electricityPerKWH: 'El per kWh',
						waterPerCM: 'Vatten per m³',
						districtHeatingPerKWH: 'Fjärrvärme per kWh',
						faultyValuesError: 'Kunde inte spara p.g.a. felaktiga värden.',
						sms: 'Sms',
						DUCAlarm: 'DUC-larm',
						changePassword: 'Ändra lösenord',
						alarmNotifications: 'Larmnotifikationer',
						energyPrices: 'Energipriser i kr',
						provideNewPasswordUser: 'Ange ditt nuvarande och nya lösenord.',
						newPassword: 'Nytt lösenord',
						confirmNewPassword: 'Bekräfta nytt lösenord',
						//newPasswordNotSame: 'De angivna lösenorden är inte de samma.',
						UpdateUserDetails: 'Uppdatera information',
						cancelled: 'Avbruten!',
						cancelPassword: 'Lösenordet har inte ändrats',
						cancelInfo: 'Informationen har inte ändrats',
						saveInfo: 'Informationen har uppdaterats',
						savePassword: 'Lösenordet har uppdaterats',
						success: 'Lyckat!',
						createUser: 'Användaren har skapats',
						deleteUser: 'Användaren har raderats',
						newPasswordSame: 'Det nya lösenordet är samma som det gamla.',
						currentPasswordNotSame: 'Det angivna lösenordet är inte ditt nuvarande lösenord.',
						currentPassword: 'Nuvarande lösenord',
						newPasswordNotSame: 'Nytt och bekräfta lösenordsfel.',
						userAlreadyExists: 'Användaren finns redan.',
						userNameOrEmailAlreadyExists: 'Användarnamn eller email finns redan.',
						emailAddressNotMatched: 'Emailadresserna matchar inte.',
						firstNameOrLastNameMissing: 'Förnamn och efternamn är obligatoriska',
					},
					maps: {
						propertyFilter: 'Fastighetsfilter:',
						onlyAlarmed: 'Endast larmande:',
						alarms: 'Larm:',
						additionalAlarms: 'larm till.',
					},
					overview: {
						kr: 'kr',
						tkr: 'tkr',
						of_lowercase: 'i',
						allProps_lowercase: 'samtliga fastigheter',
						selectedCities_lowercase: 'valda städer',
						selectedProps_lowercase: 'valda fastigheter',
						selectedSens_lowercase: 'valda sensorer',
						connectedProps: 'Uppkopplade fastigheter',
						alarmCount: 'Totalt antal larm',
						notAckedAlarmCount: 'Antal ej kvitterade larm',
						noticeCount: 'Antal avvikelser',
						cumulativeConsumptionFor: 'Ackumulerad energiförbrukning för',
						duringLast: 'under senaste',
						energyPricesTitle: 'Energipriser i kr:',
						loadingMsg: 'Förbereder översiktssida...',
					},
					reports: {
						generateForHeader: 'Generera rapport för:',
						otherInterval: 'Annat intervall',
						failedMsg: 'Rapportgenerering misslyckades.',
						loadingMsg: 'Genererar rapport...',
						errorOccurred: 'Ett fel uppstod.',
						couldNotGenerate: 'Rapporten kunde inte genereras.',
						couldNotGenerateAlert: '{{file}} filen kunde inte genereras.',
						csvIncludeProperties: 'Inkludera sensorer i sensortabellen.',
						reportRecipients: 'Rapportmottagare',
						addRecipient: 'Lägg till mottagare',
						removeRecipient: 'Ta bort mottagare',
						extraPagesHeader: 'Inkluderade extrasidor:',
						changeExtraPages: 'Ändra val av extrasidor',
						includedSensors: 'Inkluderade sensorer',
						includedProperties: 'Inkluderade fastigheter',
						changeSensors: 'Ändra val av sensorer',
						changeProperties: 'Ändra val av fastigheter',
						selectTimeIntervals: 'Välj tidsintervall',
						selectTimeIntervalsMsg: 'För vilket tidsintervall skall rapporten genereras?',
						generate: 'Generera',
						beginning: 'Början',
						end: 'Slut',
						addRecipientMsg: 'Vad är den nya mottagarens emailadress och hur ofta skall de få en rapport tillskickade?',
						selectExtraPages: 'Välj extrasidor',
						page: 'Sida',
						selectSensorsReport: 'Välj sensorer',
						numberSelectedSensors: 'Antal valda sensorer: ',
						selectPropertiesReport: 'Välj fastigheter',
						numberSelectedProperties: 'Antal valda fastigheter: ',
						confirmRemovalUser: 'Bekräfta borttagande av mottagare',
						reportsNotSentTo: 'Rapporter kommer inte längre skickas till ',
						confirmChangeSensors: 'Bekräfta ändring av sensorer',
						confirmChangeProperties: 'Bekräfta ändring av fastigheter',
						addedSensor_one: ' tillagd sensor',
						addedSensor_any: ' tillagda sensorer',
						addedProperties_any: ' tillagda fastigheter',
						addedSensor: 'Sensorer tillagda: {count}',
						and: ' och ',
						removedSensor_one: ' borttagen sensor',
						removedSensor_any: ' borttagna sensorer',
						removedProperties_any: ' borttagna fastigheter',
						noChangesFound: 'Inga ändringar kunde hittas.',
						group: 'Grupp',

						deleteReportTitle: 'Radera rapport',
						deleteReportText: 'Är du säker på att du vill radera följande rapport?',
						createPdfButton: 'PDF Rapport',
						createCsvButton: 'CSV Rapport',
						reportsTableTitle: 'Dina Rapporter',
						turnOffDeliver: 'Stäng av rapportleverans',
						turnOnDeliver: 'Slå på rapportleverans',
						createReport: 'Skapa rapport',
						chooseRecipients: 'Välj mottagare',
						saveRecipient: 'Spara mottagare',
						saveReport: 'Spara rapport',
						confirmSaveTitle: 'Är du säker på att du vill spara ändringarna?',
						confirmSaveMessage: 'Tryck på bekräfta, ändringarna kommer att sparas i rapporten.',
					},
					reportPopups: {
						report: 'Rapport',
						reportSettingsTitle: 'Konfigurera Rapport',
						pagesTitle: 'Inkluderade Sidor',
						propertiesTitle: 'Fastigheter',
						sensorsTitle: 'Individuella Sensorer',
						quickGenerateButton: 'Snabbgenerera',
						subscribeButton: 'Prenumerera rapport',
						noPageSelectedError: 'Du måste välja åtminstone en sida att inkludera i rapporten.',
						wrongEmailFormatError: 'Emailadressen är i fel format.',
						noIntervalsSelectedError: 'Du måste välja ett tidsintervall för rapporten.',
						noReportNameError: 'Du måste ange ett namn för rapporten.',
						defaultReportNameWarning:
							'Du använder standardnamnet för rapporten. Ändra namnet till något mer beskrivande eller tryck på "{{buttonName}}" för att fortsätta.',
						recipientInProgressError:
							'En mottagare är ej sparad. Klicka på "{{addRecipient}}" för att spara eller på "{{buttonName}}" igen för att fortsätta utan mottagare.',
						addRecipient: '+ Spara Mottagare',
						noSensorsSelectedError: 'Du måste välja åtminstone en sensor att inkludera i rapporten.',
						noSensorsCsvError: 'Du måste välja åtminstone en sensor att kunna generera en CSV-fil.',
						csvDownloadError: 'Det gick inte att ladda ner CSV-filen. Försök igen.',
						csvDownloadButton: 'Ladda ner som CSV',
						noPropertySelectedError: 'Du måste välja åtminstone en fastighet att inkludera i rapporten.',
					},
					registrationWizard: {
						import: 'Import',
						sensorGroups: 'Sensorgrupper',
						IP: 'IP',
						port: 'Port',
						ducID: 'DUC-ID',
						url: 'URL',
						noCitySelected: 'ingen stad vald',
						noAddressSelected: 'igen adress vald',
						existingProperty: 'Befintlig fastighet',
						selectProperty: 'Välj en fastighet...',
						newProperty: 'Ny fastighet',
						cadastral: 'Fastighetsbeteckning',
						squareMetres: 'Kvadratmeter',
						longitude: 'Longitud',
						latitude: 'Latitud',
						existingDUC: 'Befintlig DUC',
						selectDuc: 'Välj en DUC...',
						importDucPromt: 'För att DUC-fil skall kunna importeras behöver IP, port och DUC-ID vara angivna',
						unknownFileTypePromt: 'Okänd filtyp, kunde inte laddas upp.',
						nonRegisteredSensors: 'Oregistrerade sensorer',
						numberOfSelectedSensors: 'Antal valda sensorer: ',
						sensorsPromt: 'Sensorer: ',
						deleteSensorGroup: 'Radera sensorgrupp',
						addSensorGroup: 'Lägg till sensorgrupp',
						selected: ' Vald(a)', // TODO: Check source file for explination
						existing: 'befintlig',
						new: 'ny',
						freestandingSensors: 'Sensorer (fristående)',
						ducSensors: 'DUC Sensorer',
						readWrite: '(läs/skriv)',
						read: '(läs)',
						write: '(skriv)',
						continue: 'Fortsätt',
						change: 'Ändra',
						upload: 'Ladda upp',
						success: 'Fastighetsinformation har uppdaterats',
						utilitySensors: 'Nyttofunktionssensorer',
						supplyTemp: 'Framledningstemp.',
						outdoorTemp: 'Utetemp.',
						electricity: 'El',
						selectSensor: 'Välj sensor...',
						modbus: 'Modbus',
						aiControl: 'AI-kontroll har lagts till.',
						curve: 'Kurva',
						indoorTempSensor: 'Välj inomhustemperatursensorer.',
						thresholds: 'Tröskelvärden',
					},
					toolbar: {
						create: 'Skapa',
						fullscreen: 'Helskärm',
						timeInterval: 'Tidsintervall',
						updateRate: 'Uppdateringsfrekvens',
						editMode: 'Redigera',
						normalize: 'Relativ Skala',
					},
					wizardAdmin: {
						propertyAndSensors: 'Fastighet + Sensorer',
						DUCImport: 'DUC Import',
					},
					importAdmin: {
						change: 'Ändra',
						upload: 'Ladda upp',
						DUC: 'DUC:',
						file: 'Fil:',
						unkownFilePromt: 'Okänd filtyp, kunde inte laddas upp.',
						registersAdded: 'Register har lagts till',
					},
					notify: {
						notifications: 'Notifikationer',
						logOut: 'Logga ut',
						userInfo: 'Användarinfo',
						userName: 'Användarnamn',
						phoneNumber: 'Telefonnummer',
						whatsNew: 'Uppdateringar',
						changeLogs: 'Uppdateringshistorik',
					},
					changelog: {
						updatedAt: 'Reviderad:',
					},
					notificationList: {
						new: 'Ny',
						thresholdValues: 'Tröskelvärden',
						timestamp: 'Tidsstämpel',
						notificationAlarmsTitle: 'Notifikationer (larm på prenumererade sensorer)',
					},
					modalSettings: {
						previousYear: 'Föregående År',
						currentYear: 'Nuvarande År',
						previousMonth: 'Föregående Månad',
						sameMonthPreviousYear: 'Samma Månad, Föregående År',
						previousWeek: 'Föregående Vecka',
						previous: 'Föreg.',
					},
					analysis: {
						optimization: 'Optimering',
						prediction: 'Prediktering',
						machineLearning: 'Maskininlärning',
						deepAnalysis: 'Djupanalys',
						regressionAnalysis: 'Regressionsanalys',
						temperature: 'Temperatur',
						temperatureTrend: 'Temperatur trend',
						energy: 'Energi',
						topConsumer: 'Topp förbrukare',
						latest24h: 'Senaste 24 timmar',
						siteUnavailable: 'Sida otillgänglig',
						siteNotInSubscription: 'Sidan ingår för tillfället inte i ert abonnemang.',
					},
					assignments: {
						underConstruction: 'Under utveckling.',
					},
					autoUpdate: {
						chipPrefix: 'Uppdateringsintervall',
						off: 'Av',
						minutes5: 'Var 5:e minut',
						minutes10: 'Var 10:e minut',
						minutes15: 'Var 15:e minut',
						minutes30: 'Var 30:e minut',
						hours1: 'Varje timme',
						hours2: 'Varannan timme',
						hours4: 'Var 4:e timme',
						hours24: 'Varje dag',
					},
					issues: {
						underConstruction: 'Under utveckling.',
					},
					links: {
						linkNotFound: 'Länk kunde inte hittas.',
					},
					IELandingPage: {
						browserNotSupported: 'Er webbläsare stöds ej.',
						hi: 'Hej!',
						doesNotSupportPromt: `Detta verktyg stöder tyvärr inte er webbläsare Internet Explorer.
						Ifall ni har Microsoft Edge installerat på er maskin, byt då vänligen till den webbläsaren och kom därefter tillbaka.
						Tryck annars på länken nedan för att ladda ner och installera Microsoft Edge.`,
						pressToDownload: 'Tryck här för att ladda ner Microsoft Edge.',
					},
					automations: {
						name: 'Namn',
						communicationType: 'Kommunikationstyp',
						sensorType: 'Sensortyp',
						system: 'System',
						minimum: 'Minimum',
						maximum: 'Maximum',
						value: 'Värde',
						automationInfo: 'Automationsinformation har uppdaterats',
						sensor_or_tag: 'Sensor/Tag',
						selectedSensorsOrTags: 'Valda sensorer/tags',
						sensor: 'Sensor',
						addAIcontrol: 'Lägg till AI-kontroll',
						delete: 'Radera',
						deleteSelectedSensorsOrTags: 'Bekräfta borttagning av valda sensorer/tags',
						deletedSensorsTags: 'Valda sensorer/tags har raderats',
						addedAIcontrol: 'AI-kontroll har lagts till',
						addSensor: 'Lägg till sensor',
						sensor: 'Sensor',
						modbus: 'Modbus',
						supplyTemp: 'Framledningstemp.',
						outdoorTemp: 'Utetemp.',
						electricity: 'El',
						indoorTemp: 'Inomhustemp.',
						ducid: 'DUC-ID',
						aiSetupError: 'Ett fel inträffade när vi försökte sätta upp AI-styrningen.',
						curveChange: 'Värmekurva Förskjutning',
						averageIndoorTemperture: 'Medelvärde Inomhustemperatur',
					},
					aiOverview: {
						reportFor: 'Rapport för',
						energySaved: 'Energi besparad',
						energyConsumed: 'Total förbrukning',
						guageTitle: 'Medelvärde inomhustemperatur',
						energySavedInfo: '*Minskning på 1°C är lika med 5% energibesparing (-5%)',
						energyConsumedInterval: 'Total förbrukning för nuvarande intervall',
						// Errors
						requiresCurveChange: 'Kräver kurvändringsensorer',
						requiresDistrictHeating: 'Kräver fjärrvärmesensorer, vänligen lägg till dessa',
						noSensorOfThisType: 'Inga sensorer av denna typ har lagts till',
						dataFetchError: 'Det gick inte att hämta datan, försök igen',
						// Curve graph
						optimized: 'Optimiserad kurva',
						original: 'Orginalkurva',
						min: 'Min. kurva',
						max: 'Max. kurva',
					},
					companyName: {
						bastec: 'Bastec Cirrus',
						meliox: 'Meliox Connect',
						swegon: 'Swegon INSIDE Director',
						sandbackens: 'Sandbäckens Connect',
					},
					loginLink: {
						bastec: 'https://bastec.meliox.se/',
						meliox: 'https://connect.melioxdev.se/',
						swegon: 'https://swegon.meliox.se/',
						sandbackens: 'https://connect.meliox.se/',
					},
					welcomeMessage: {
						welcomeMessageBody:
							'Hej {{customerName}},\n\nVälkommen till {{companyName}}! Vi är glada att vi kan stötta dig  med att optimera din fastighets förvaltning och energieffektivitet genom vår molnbaserade plattform.\n\nTo get started, För att komma igång har vi skapat ditt konto med följande detaljer:\n𝐀𝐧𝐯ä𝐧𝐝𝐚𝐫𝐧𝐚𝐦𝐧: {{username}}\n𝐋ö𝐬𝐞𝐧𝐨𝐫𝐝: {{password}}\n\nVänligen klicka på följande länk för att logga in på ditt {{companyName}} -konto: {{loginLink}}\n\nMed {{companyName}}, får du tillgång till en omfattande verktygssvit för:\n- Övervakning och hantering av dina fastigheters system och sensorer.\n-  Analys av data för att optimera energiförbrukning och minska kostnader.\n-  Effektivisering av förvaltning över hela din fastighetsportfölj.\n\nTack för att du har valt {{companyName}}. Låt oss tillsammans göra dina fastigheter smarta och mer hållbara.\n\nVänliga hälsningar,\nTeam {{companyName}}',
						welcomeMessageSubject: 'Välkommen till {{companyName}}',
					},
					welcomeSpecial: {
						welcomeMessageBody:
							'Hej {{customerName}},\n\nVälkommen till {{companyName}}! Vi är glada att vi kan stötta dig  med att optimera din fastighets förvaltning och energieffektivitet genom vår molnbaserade plattform.\n\nTo get started, För att komma igång har vi skapat ditt konto med följande detaljer:\n𝐀𝐧𝐯ä𝐧𝐝𝐚𝐫𝐧𝐚𝐦𝐧: {{username}}\n𝐋ö𝐬𝐞𝐧𝐨𝐫𝐝: {{password}}\n\nVänligen klicka på följande länk för att logga in på ditt {{companyName}} -konto: {{loginLink}}\n\nMed {{companyName}}, får du tillgång till en omfattande verktygssvit för:\n- Övervakning och hantering av dina fastigheters system och sensorer.\n-  Analys av data för att optimera energiförbrukning och minska kostnader.\n-  Effektivisering av förvaltning över hela din fastighetsportfölj.\n\nOm du har några frågor eller behöver hjälp, tveka inte att kontakta oss på {{contactEmail}}.\n\nTack för att du har valt {{companyName}}. Låt oss tillsammans göra dina fastigheter smarta och mer hållbara.\n\nVänliga hälsningar,\nTeam {{companyName}}',
						welcomeMessageSubject: 'Välkommen till {{companyName}}',
					},
					supportEmail: {
						bastec: 'support@bastec.se',
						meliox: 'support@meliox.se',
						swegon: 'support@swegon.se',
						sandbackens: 'support@sandbackens.se',
					},
				},
			},
		},
	});

export default i18n;
